import { Vue, Options } from "vue-class-component";

import ResearchJobListItem from "../../components/ResearchJobListItem";
import ResearchJobFilter from "../../components/ResearchJobFilter";
import Notifications from "../../components/Notifications";
import LeftSideMenu from "../../components/LeftSideMenu";
import { ResearchJobApi } from "../../services/http";
import { Events, EventTypes, calcAlternativeIndex } from "../../services/utils";

@Options({
  components: {
    ResearchJobListItem,
    ResearchJobFilter,
    LeftSideMenu,
  },
})
export default class ResearchJobSearchView extends Vue {
  resultsData: any;
  hasMoreResults = true;
  searchParams = {} as any;
  page = 0;

  removeDelay = 100;

  calcAlternativeIndex = calcAlternativeIndex;

  created() {
    this.searchParamsChanged(this.searchParams);
  }

  async getJobs(page: number, params: any, reset = false) {
    const data = await ResearchJobApi.search({ ...params, page });
    let results = this.resultsData ? this.resultsData.results : [];
    if (reset) results = [];
    results.push(...data.results);

    this.resultsData = Object.assign(this.resultsData || {}, {
      ...data,
      results,
    });

    if (data.results && data.results.length > 0) this.page = page;

    this.hasMoreResults = !!data.next;

    if (reset) {
      const scrollElm = this.getScrollElement();
      if (scrollElm) scrollElm.scrollTop = 0;
    }

    this.$forceUpdate();
  }

  getScrollElement(): HTMLElement | null {
    return this.$refs["scrollContainer"] as HTMLElement;
  }

  async loadMoreResults(event: Event) {
    const scrollElm = this.getScrollElement()!;
    const scrollToBottomLength =
      scrollElm.scrollHeight - scrollElm.offsetHeight - scrollElm.scrollTop;
    const atBottom = scrollToBottomLength < 3;

    if (atBottom && this.hasMoreResults)
      this.getJobs(this.page + 1, this.searchParams);
  }

  async searchParamsChanged(params: any) {
    this.searchParams = params;
    this.getJobs(1, params, true);
  }
}
