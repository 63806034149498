import { Vue, Options } from "vue-class-component";

import MemberSearchListItem from "../MemberSearchListItem";
import Notifications from "../Notifications";
import { MemberApi } from "../../services/http";
import { Events, EventTypes, calcAlternativeIndex } from "../../services/utils";
import { Member, CONNECT_STATUS_TYPE, CONNECT_ACTIONS } from "../../models";

@Options({
  components: {
    MemberSearchListItem,
  },
})
export default class MemberSentRequests extends Vue {
  resultsData: any;
  hasMoreResults = true;
  searchParams = {} as any;
  page = 0;

  removeDelay = 100;
  calcAlternativeIndex = calcAlternativeIndex;
  getConnectStatusView = () => CONNECT_STATUS_TYPE.i_requested;

  created() {
    this.searchParamsChanged(this.searchParams);
  }

  mounted() {
    Events.on(EventTypes.memberUpdate, this.onMemberUpdate);
  }

  beforeUnmount() {
    Events.off(EventTypes.memberUpdate, this.onMemberUpdate);
  }

  async getMembers(page: number, params: any, reset = false) {
    const data = await MemberApi.getSentRequests({ ...params, page });
    let results = this.resultsData ? this.resultsData.results : [];
    if (reset) results = [];
    results.push(...data.results);

    this.resultsData = Object.assign(this.resultsData || {}, {
      ...data,
      results,
    });

    if (data.results && data.results.length > 0) this.page = page;

    this.hasMoreResults = !!data.next;

    if (reset) {
      const scrollElm = this.getScrollElement();
      if (scrollElm) scrollElm.scrollTop = 0;
    }

    this.$forceUpdate();
  }

  getScrollElement(): HTMLElement | null {
    return this.$refs["scrollContainer"] as HTMLElement;
  }

  async loadMoreResults(event: Event) {
    const scrollElm = this.getScrollElement()!;
    const scrollToBottomLength =
      scrollElm.scrollHeight - scrollElm.offsetHeight - scrollElm.scrollTop;
    const atBottom = scrollToBottomLength < 3;

    if (atBottom && this.hasMoreResults)
      this.getMembers(this.page + 1, this.searchParams);
  }

  async searchParamsChanged(params: any) {
    this.searchParams = params;
    this.getMembers(1, params, true);
  }

  async onConnectStatusUpdated(actionType: CONNECT_ACTIONS, member: Member) {
    const results = (this.resultsData?.results || []) as Member[];
    const existing = results.find((m) => m.id === member.id);
    if (existing) Object.assign(existing, { ...member });

    Events.emit(EventTypes.connectionStatusUpdated, member);

    let message = "";
    switch (actionType) {
      case CONNECT_ACTIONS.accept:
        message = `You are now connected to ${member.name}`;
        break;
      case CONNECT_ACTIONS.connect:
        message = `Connection request sent to ${member.name}`;
        break;
      case CONNECT_ACTIONS.cancel:
        message = `Your connection request has been withdrawn`;
        break;

      case CONNECT_ACTIONS.remove:
        message = `This connection has been removed`;
        break;

      case CONNECT_ACTIONS.decline:
        message = `You have declined this connection request`;
        break;
    }

    if (message) Notifications.toast(message);

    const connectStatusView = this.getConnectStatusView();

    if (
      connectStatusView &&
      existing &&
      existing.connect_status !== connectStatusView
    ) {
      setTimeout(
        () => {
          const results = (this.resultsData?.results || []) as Member[];
          const index = results.findIndex((m) => m.id === existing.id);
          if (index >= 0) results.splice(index, 1);

          this.resultsData = { ...this.resultsData, results };
          this.$forceUpdate();
        },
        actionType === CONNECT_ACTIONS.accept ? 1000 : 300
      );
    }
  }

  onMemberUpdate(member: Member) {
    const results = (this.resultsData?.results || []) as Member[];
    const existing = results.find((m) => m.id === member.id);
    if (existing) {
      const index = results.indexOf(existing);
      const newUpdated = Object.assign({}, existing, member);
      results[index] = newUpdated;
      this.$forceUpdate();
    }
  }
}
