import { Vue, Options } from "vue-class-component";
import { AuthApi } from "../../services/http";
import Notifications from "../../components/Notifications";
import FormField from "../../components/FormField";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

@Options({
  components: {
    FormField,
    Header,
    Footer,
  },
})
export default class ResetPassword extends Vue {
  redirectQSearch = "";
  token!: string;

  formData = {
    password: "",
    password2: "",
  };

  getRedirectUrl(): string | undefined {
    const query = this.$route.query;
    if (query && query.redirect) return query.redirect as string;
  }

  created() {
    this.token = this.$route.params.token as string;
    const redirectUrl = this.getRedirectUrl();
    if (redirectUrl) this.redirectQSearch = `?redirect=${redirectUrl}`;
  }

  async onFormSubmit($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
    $event.stopImmediatePropagation();

    if (this.formData.password !== this.formData.password2) {
      Notifications.alert("Make sure the passwords match", [
        { text: "Okay", value: "" },
      ]);
      return;
    }

    const member = await AuthApi.resetPassword({
      ...this.formData,
      token: this.token,
    });
    const redirectUrl = this.getRedirectUrl();

    window.location.href = redirectUrl || "/home";
  }

  onInputUpdate(name: "password" | "password2", inputEvent: InputEvent) {
    const inputEl = inputEvent.target as HTMLInputElement;
    this.formData[name] = inputEl.value;
  }
}
