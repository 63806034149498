import {
  Member,
  ResearchJob,
  ResearchConference,
  ResearchCommunity,
  ResearchLibrary,
  CONNECT_STATUS_TYPE,
  ConnectionStats,
  Feeds,
  FeedsComment,
  FeedsLikes,
  FeedsdisLikes,
  FeedsShare,
} from "../models";
import { ResearchGrant } from "@/models/research_grants";
import axios from "axios";
import {
  requestInterceptor,
  responseInterceptor,
  AuthTokenHandler,
  responseErrorInterceptor,
} from "./interceptor";

import { Events, EventTypes } from "../services/utils";
import { Stats } from "../services/data";

import { getRocketChatIFrame, logoutRocketChat } from "./rocket-chat";

const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;

const instance = axios.create({
  baseURL: `${API_ENDPOINT}/api`,
});

instance.interceptors.request.use(requestInterceptor);
instance.interceptors.response.use(
  responseInterceptor,
  responseErrorInterceptor
);

export class MemberApi {
  static async search(params: {
    q?: string;
    relationship_position?: string;
    availability_start?: string;
    availability_end?: string;
    project_running?: boolean;
  }): Promise<{ results: Member[]; next: any; page: number; count: number }> {
    return instance.get("/members", { params });
  }

  static async getReceivedRequests(
    params: any
  ): Promise<{ results: Member[]; next: any; page: number; count: number }> {
    return instance
      .get("/members/other_connection_requests", { params })
      .then((data: any) => {
        Stats.connectionStats.other_requested = data.count;
        Events.emit(EventTypes.statsUpdated, Stats);
        return data;
      });
  }

  static async getSentRequests(
    params: any
  ): Promise<{ results: Member[]; next: any; page: number; count: number }> {
    return instance
      .get("/members/i_connection_requests", { params })
      .then((data: any) => {
        Stats.connectionStats.i_requested = data.count;
        Events.emit(EventTypes.statsUpdated, Stats);
        return data;
      });
  }

  static async getConnections(
    params: any
  ): Promise<{ results: Member[]; next: any; page: number }> {
    return instance
      .get("/members/connections", { params })
      .then((data: any) => {
        Stats.connectionStats.connected = data.count;
        Events.emit(EventTypes.statsUpdated, Stats);
        return data;
      });
  }

  static async getProfile(id: string): Promise<Member> {
    return instance.get(`/members/${id}`);
  }

  static async requestConnection(id: number): Promise<Member> {
    return instance.post(`/members/${id}/request_connection`);
  }

  static async acceptConnection(id: number): Promise<Member> {
    return instance.post(`/members/${id}/accept_connection`);
  }

  static async declineConnection(id: number): Promise<Member> {
    return instance.post(`/members/${id}/decline_connection`);
  }

  static async cancelConnectionRequest(id: number): Promise<Member> {
    return instance.post(`/members/${id}/cancel_connection`);
  }

  static async removeConnection(id: number): Promise<Member> {
    return instance.post(`/members/${id}/remove_connection`);
  }

  static async getConnectStats(): Promise<ConnectionStats> {
    const member = await ProfileApi.getProfile();
    return member.connection_stats!;
  }
}

export class ResearchJobApi {
  static async search(params: {
    q?: string;
    job_type?: string;
    is_remote?: boolean;
  }): Promise<{ results: ResearchJob[]; next: any; page: number }> {
    return instance.get("/jobs", { params });
  }

  static async add(params: any): Promise<ResearchJob> {
    return instance.post("/jobs", params);
  }
}

export class ResearchConferenceApi {
  static async search(params: {
    q?: string;
    availability_start?: string;
    availability_end?: string;
    is_remote?: boolean;
  }): Promise<{ results: ResearchConference[]; next: any; page: number }> {
    return instance.get("/conferences", { params });
  }

  static async add(params: any): Promise<ResearchConference> {
    return instance.post("/conferences", params);
  }
}

export class ResearchCommunityApi {
  static async search(params: {
    q?: string;
  }): Promise<{ results: ResearchCommunity[]; next: any; page: number }> {
    return instance.get("/communities", { params });
  }

  static async add(params: any): Promise<ResearchCommunity> {
    return instance.post("/communities", params);
  }
}

export class ResearchLibraryApi {
  static async search(params: {
    q?: string;
  }): Promise<{ results: ResearchLibrary[]; next: any; page: number }> {
    return instance.get("/libraries", { params });
  }

  static async add(params: any): Promise<ResearchLibrary> {
    return instance.post("/libraries", params);
  }
}

export class FeedsApi {
  static async search(params: {
    q?: string;
  }): Promise<{ results: Feeds[]; next: any; page: number }> {
    return instance.get("/feeds", { params });
  }
  static async getFeed(feedId: any): Promise<Feeds> {
    return instance.get(`/feeds/${feedId}`);
  }

  static async add(params: any): Promise<Feeds> {
    return instance.post("/feeds", params);
  }
}

//export class FeedsApi {
//  static async getFeed(feedId: any): Promise<Feeds> {
//    return instance.get(`/feeds/${feedId}`);
//  }

//  static async search(params: {
//  q?: string;
// }): Promise<{ results: Feeds[]; next: any; page: number }> {
//  return instance.get("/feeds", { params });
//}

//  static async add(params: any): Promise<Feeds> {
//    return instance.post("/feeds", params);
//  }
//}

export class FeedsCommentApi {
  static async list(feedId: number): Promise<any> {
    return instance.get(`/feeds/${feedId}/comments`);
  }

  static async add(feedId: number, params: any): Promise<FeedsComment> {
    return instance.post(`/feeds/${feedId}/comments`, params);
  }
}

export class FeedsShareApi {
  static async list(feedId: number): Promise<any> {
    return instance.get(`/feeds/${feedId}/share`);
  }

  static async add(feedId: number, params: any): Promise<FeedsComment> {
    return instance.post(`/feeds/${feedId}/share`, params);
  }
}

export class FeedsLikesAPI {
  static async like(feedId: number, liked: boolean): Promise<FeedsLikes> {
    return instance.post(`/feeds/${feedId}/likes`, { liked });
  }

  static async getLikes(feedId: number): Promise<FeedsLikes> {
    return instance.get(`/feeds/${feedId}/likes`);
  }
}
export class FeedsdisLikesAPI {
  static async dislike(
    feedId: number,
    disliked: boolean
  ): Promise<FeedsdisLikes> {
    return instance.post(`/feeds/${feedId}/dislikes`, { disliked });
  }

  static async getdisLikes(feedId: number): Promise<FeedsdisLikes> {
    return instance.get(`/feeds/${feedId}/dislikes`);
  }
}

export class ResearchGrantApi {
  static async search(params: {
    q?: string;
  }): Promise<{ results: ResearchGrant[]; next: any; page: number }> {
    return instance.get("/grants", { params });
  }

  static async add(params: any): Promise<ResearchGrant> {
    return instance.post("/grants", params);
  }
}

export class ProfileApi {
  static async getProfile(): Promise<Member> {
    return instance
      .get<any, Member>("/profile/self")
      .then((m) => {
        AuthApi.member = m;
        Events.emit(EventTypes.profileApiLoaded, m);
        return m;
      })
      .catch((err) => {
        AuthApi.member = null;
        throw err;
      });
  }

  static async updateProfile(member: Member): Promise<Member> {
    return instance
      .patch<any, Member>(`/profile/${AuthApi.member!.id}`, member)
      .then((m) => {
        AuthApi.member = m;
        Events.emit(EventTypes.profileApiLoaded, m);
        return m;
      });
  }
}

export class AuthApi {
  static member?: Member | null;

  static async login(params: any): Promise<Member> {
    return instance
      .post<any, Member>("/auth/login", params)
      .then((m) => {
        AuthApi.member = m;
        Events.emit(EventTypes.profileApiLoaded, m);
        getRocketChatIFrame(m);
        AuthApi.setUpRefreshTimer();
        return m;
      })
      .catch((err) => {
        AuthApi.member = null;
        throw err;
      });
  }

  static async shiblogin(params: any): Promise<Member> {
    return instance
      .post<any, Member>("/auth/shiblogin", params)
      .then((m) => {
        AuthApi.member = m;
        Events.emit(EventTypes.profileApiLoaded, m);
        getRocketChatIFrame(m);
        AuthApi.setUpRefreshTimer();
        return m;
      })
      .catch((err) => {
        AuthApi.member = null;
        throw err;
      });
  }

  static refreshTimerId: any;
  static refreshImmediateTimeoutMinutes = 0.15;
  static refreshIntervalTimeMinutes = 60;

  static async checkLogin(): Promise<Member> {
    return instance
      .post<any, Member>("/auth/check_login")
      .then((m) => {
        AuthApi.member = m;
        Events.emit(EventTypes.profileApiLoaded, m);
        getRocketChatIFrame(m);
        AuthApi.setUpRefreshTimer();
        return m;
      })
      .catch((err) => {
        AuthApi.member = null;
        throw err;
      });
  }
  static async checkshibLogin(token: any): Promise<Member> {
    return instance
      .post<any, Member>(
        "/auth/check_login",
        {},
        {
          headers: {
            Authorization: "token " + token,
          },
        }
      )
      .then((m) => {
        AuthApi.member = m;
        Events.emit(EventTypes.profileApiLoaded, m);
        getRocketChatIFrame(m);
        AuthApi.setUpRefreshTimer();
        return m;
      })
      .catch((err) => {
        AuthApi.member = null;
        throw err;
      });
  }
  static setUpRefreshTimer() {
    AuthApi.stopRefreshTimer();
    AuthApi.refreshImmediateTimeoutMinutes = setTimeout(
      () => AuthApi.refreshLogin(),
      AuthApi.refreshImmediateTimeoutMinutes * 60 * 1000
    );
    AuthApi.refreshTimerId = setInterval(
      () => AuthApi.refreshLogin(),
      AuthApi.refreshIntervalTimeMinutes * 60 * 1000
    );
  }

  static stopRefreshTimer() {
    clearTimeout(AuthApi.refreshImmediateTimeoutMinutes);
    clearInterval(AuthApi.refreshTimerId);
  }

  static async refreshLogin(): Promise<any> {
    return instance.post<any, Member>("/auth/refresh_login");
  }

  static async registerationAttempt(params: {
    email: string;
    first_name: string;
    last_name: string;
  }): Promise<any> {
    return instance.post<any, any>("/auth/registeration_attempt", params);
  }

  static async createAccount(params: any): Promise<Member> {
    return instance.post<any, Member>("/auth/signup", params);
  }

  static async forgotPassword(params: { email: string }): Promise<any> {
    return instance.post<any, Member>("/auth/forgot_password", params);
  }

  static async resetPassword(params: {
    token: string;
    password: string;
  }): Promise<any> {
    return instance.post<any, Member>("/auth/reset_password", params);
  }

  static async logout(): Promise<any> {
    AuthApi.stopRefreshTimer();
    return instance
      .post<any, Member>("/auth/logout")
      .then(async () => {
        AuthApi.member = undefined;
        AuthTokenHandler.token = null;
        localStorage.clear();

        try {
          await logoutRocketChat(AuthApi.member!);
        } catch (error) {
          console.log(error);
        }
      })
      .catch(async (error) => {
        AuthApi.member = undefined;
        AuthTokenHandler.token = null;
        localStorage.clear();

        try {
          await logoutRocketChat(AuthApi.member!);
        } catch (error) {
          console.log(error);
        }
      });
  }

  static async isLoggedIn(refresh?: boolean): Promise<boolean> {
    if (!AuthTokenHandler.token) return false;

    if (AuthApi.member === undefined || (refresh !== undefined && refresh)) {
      await AuthApi.checkLogin().catch((err) => false);
    }

    return !!AuthApi.member;
  }
}
