import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ccd9d61"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["altIndex"]
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "avatar-container" }
const _hoisted_4 = { class: "profile-info" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = {
  key: 0,
  class: "subtitle"
}
const _hoisted_7 = { class: "body" }
const _hoisted_8 = {
  key: 0,
  class: "section"
}
const _hoisted_9 = { class: "description" }
const _hoisted_10 = { class: "section" }
const _hoisted_11 = { class: "description tag-container" }
const _hoisted_12 = {
  key: 0,
  class: "tag"
}
const _hoisted_13 = {
  key: 1,
  class: "tag"
}
const _hoisted_14 = {
  key: 2,
  class: "tag"
}
const _hoisted_15 = { class: "footer" }
const _hoisted_16 = {
  key: 0,
  class: "id-tag"
}
const _hoisted_17 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", {
    class: "root",
    altIndex: _ctx.altIndex
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.library.name), 1),
          (_ctx.library.tag_line)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.library.tag_line), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      (_ctx.library.created)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createVNode(_component_inline_svg, {
              class: "icon",
              src: "/assets/icons/about.svg"
            }),
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.library.created), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_inline_svg, {
          class: "icon",
          src: "/assets/icons/tags.svg"
        }),
        _createElementVNode("div", _hoisted_11, [
          (_ctx.library.tags?.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, " #" + _toDisplayString(_ctx.library.tags[0]), 1))
            : _createCommentVNode("", true),
          (_ctx.library.tags?.length > 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, " #" + _toDisplayString(_ctx.library.tags[1]), 1))
            : _createCommentVNode("", true),
          (_ctx.library.tags?.length > 2)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, " +" + _toDisplayString(_ctx.library.tags.length - 2) + " more ", 1))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_15, [
      (_ctx.showId)
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, "#" + _toDisplayString(_ctx.library.id), 1))
        : _createCommentVNode("", true),
      _createElementVNode("a", {
        class: "action-btn",
        more: "",
        href: _ctx.library.external_link,
        target: "_blank",
        onClickCapture: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onLibraryClick && _ctx.onLibraryClick(...args)))
      }, " click for more details ", 40, _hoisted_17)
    ])
  ], 8, _hoisted_1))
}