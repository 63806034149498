import { createApp } from "vue";
import { IonicVue } from "@ionic/vue";
import App from "./App.vue";
import router from "./routes";
import { filters } from "../filters";
import { openMenu, closeMenu } from "../services/utils";
import moment from "moment";
import InlineSvg from "vue-inline-svg";
import {
  IonContent,
  IonPage,
  IonHeader,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";
import CompleteProfile from "../components/CompleteProfile";
import LeftSideMenu from "../components/LeftSideMenu";

declare const window: any;
window.moment = moment;

const app = createApp(App).use(IonicVue);

app.config.globalProperties.$filters = filters;
app.config.globalProperties.openMenu = openMenu;
app.config.globalProperties.closeMenu = closeMenu;

app.config.globalProperties.$preventEnterSubmit = (event: KeyboardEvent) => {
  if (event.keyCode === 13) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
  }
};
app.use(router);
app.component("inline-svg", InlineSvg);
app.component("LeftSideMenu", LeftSideMenu);
app.mount("#app");

export default app;
