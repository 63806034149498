import { Vue, Options } from "vue-class-component";
import { PropType } from "vue";

import moment from "moment";

import { MemberListActions, MemberConnectActions } from "../../services/data";
import { Events, EventTypes, gaEvent } from "../../services/utils";
import { PromptProfileCompletion } from "../../services/prompt-profile-completion";
import { MemberApi, AuthApi } from "../../services/http";
import {
  Member,
  CONNECT_ACTIONS,
  CONNECT_STATUS_TYPE,
  GA_ACTIONS,
  GA_CATEGORIES,
} from "../../models";
import Notifications from "../../components/Notifications";

@Options({
  props: {
    member: Object as PropType<Member>,
    altIndex: String,
  },
})
export default class MemberSearchListItem extends Vue {
  member!: Member;
  CONNECT_ACTIONS = CONNECT_ACTIONS;
  CONNECT_STATUS_TYPE = CONNECT_STATUS_TYPE;

  altIndex = "a";

  private memberActions: any[] = [];
  private memberConnectActions: any;

  availableText() {
    const mDate = moment(this.member.research_preference.availability_start);

    if (mDate.isValid()) return mDate.format("MMM DD, YYYY");
    else return "N/A";
  }

  created() {
    this.memberActions = MemberListActions;
    this.memberConnectActions = MemberConnectActions;
  }

  onActionClick(actionType: CONNECT_ACTIONS) {
    switch (actionType) {
      case CONNECT_ACTIONS.connect:
        gaEvent(GA_ACTIONS.make_connection_request, GA_CATEGORIES.connections);
        this.connect();
        break;
      case CONNECT_ACTIONS.accept:
        gaEvent(GA_ACTIONS.accept_connection, GA_CATEGORIES.connections);
        this.accept();
        break;
      case CONNECT_ACTIONS.decline:
        gaEvent(GA_ACTIONS.decline_connection, GA_CATEGORIES.connections);
        this.decline();
        break;
      case CONNECT_ACTIONS.cancel:
        gaEvent(GA_ACTIONS.cancel_connection, GA_CATEGORIES.connections);
        this.cancel();
        break;
      case CONNECT_ACTIONS.remove:
        gaEvent(GA_ACTIONS.remove_connection, GA_CATEGORIES.connections);
        this.remove();
        break;
    }
  }

  updatedConnectStatus(actionType: CONNECT_ACTIONS, member: Member) {
    this.$emit("updatedConnectStatus", actionType, member);
    Object.assign(this.member, member);
    this.$forceUpdate();
  }

  async connect() {
    if (
      !AuthApi.member!.profile_complete &&
      !(await PromptProfileCompletion(AuthApi.member!, {
        title: "Profile update required",
        subtitle: "Update your profile before initiating a connection request",
      }))
    )
      return Notifications.toast({
        title:
          "You won’t be able to initiate a connection request unless your profile is updated",
        duration: 3000,
      });

    const member = await MemberApi.requestConnection(this.member.id);
    Events.emit(EventTypes.memberUpdate, member);
    this.updatedConnectStatus(CONNECT_ACTIONS.connect, member);
  }

  async cancel() {
    const member = await MemberApi.cancelConnectionRequest(this.member.id);
    Events.emit(EventTypes.memberUpdate, member);
    this.updatedConnectStatus(CONNECT_ACTIONS.cancel, member);
  }

  async accept() {
    const member = await MemberApi.acceptConnection(this.member.id);
    Events.emit(EventTypes.memberUpdate, member);
    this.updatedConnectStatus(CONNECT_ACTIONS.accept, member);
  }

  async decline() {
    const member = await MemberApi.declineConnection(this.member.id);
    Events.emit(EventTypes.memberUpdate, member);
    this.updatedConnectStatus(CONNECT_ACTIONS.decline, member);
  }

  async remove() {
    const confirm = await Notifications.alert<boolean>(
      `Are you sure you want to remove ${this.member.name} as a connection?`,
      [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ]
    );
    if (confirm !== true) return;
    const member = await MemberApi.removeConnection(this.member.id);
    Events.emit(EventTypes.memberUpdate, member);
    this.updatedConnectStatus(CONNECT_ACTIONS.remove, member);
  }

  onMessageMember($event: Event) {
    gaEvent(GA_ACTIONS.message_member, GA_CATEGORIES.messaging);
  }

  onViewMemberProfile($event: Event) {
    gaEvent(GA_ACTIONS.view_profile, GA_CATEGORIES.profiles, this.member?.id);
  }
}
