import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1460b94e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "field-container q" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!

  return (_openBlock(), _createElementBlock("form", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_FormField, {
        type: "text",
        label: "Search by name, areas of interest, or tags",
        placeholder: "search by name, areas of interest, tags",
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.throttleInput('q', $event)))
      })
    ])
  ]))
}