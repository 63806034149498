import { Vue, Options } from "vue-class-component";
import { useRoute } from "vue-router";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { AuthApi } from "../../services/http";

@Options({
  components: {
    Header,
    Footer,
  },
})
export default class ShiboLogin extends Vue {
  created() {
    const route = useRoute();
  }

  mounted() {
    this.login();
  }

  async login() {
    //const member = await AuthApi.checkshibLogin(ShiboLogin.token);
    try {
      const member = await AuthApi.checkshibLogin(this.$route.query.token);
      const token = this.$route.query.token;

      window.location.href = "/home";
    } catch (e) {
      window.location.href = "/login";
    }
  }
}
