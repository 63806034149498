import { Vue, Options } from "vue-class-component";
import { PropType } from "vue";

import moment from "moment";

import { ResearchConference, GA_ACTIONS, GA_CATEGORIES } from "../../models";
import Notifications from "../../components/Notifications";
import { gaEvent } from "../../services/utils";

@Options({
  props: {
    conference: Object as PropType<ResearchConference>,
    altIndex: String,
  },
})
export default class ResearchConferenceListItem extends Vue {
  conference!: ResearchConference;

  altIndex = "a";
  showId = false;

  onConferenceClick(event: Event) {
    gaEvent(
      GA_ACTIONS.view_conference,
      GA_CATEGORIES.conferences,
      this.conference?.id
    );
  }

  created() {
    this.showId =
      new URLSearchParams(location.search).get("show_id") === "true";
  }
}
