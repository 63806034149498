import { Vue, Options } from "vue-class-component";
import { ResearchJobApi } from "../../services/http";
import Notifications from "../../components/Notifications";
import FormField from "../../components/FormField";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import { JobType } from "../../models";

@Options({
  components: {
    FormField,
    Header,
    Footer,
  },
})
export default class AddResearchJob extends Vue {
  jobTypeOptions = [
    { name: "Any job type", value: "" },
    { name: "Full time", value: JobType.full_time },
    { name: "Part time", value: JobType.part_time },
    { name: "Contract", value: JobType.contract },
    { name: "Internship", value: JobType.internship },
  ];

  formData = {
    name: "",
    company_name: "",
    job_type: "",
    is_remote: false,
    application_deadline: "",
    external_link: "",
    added_by_public: true,
    tags: [] as string[],
  };

  getRedirectUrl(): string | undefined {
    const query = this.$route.query;
    if (query && query.redirect) return query.redirect as string;
  }

  async onFormSubmit($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
    $event.stopImmediatePropagation();

    const form = this.$refs["form"] as HTMLFormElement;
    //const logo = form.querySelector(`input[name="logo"]`)! as HTMLInputElement;
    const formData = new FormData();
    Object.keys(this.formData).forEach((key) => {
      const value = (this.formData as any)[key];
      if (!(key === "application_deadline" && !value)) {
        formData.set(key, value);
      }
    });

    //formData.set("logo", logo.files!.item(0)!);

    const job = await ResearchJobApi.add(formData);

    const callback = () => {
      // Redirect to the home page
      this.$router.push("/"); // Adjust the route as needed
    };

    Notifications.alert(
      "Thanks for this posting, this will be reviewed by PeerEDGE and added to the platform in the next few days",
      [{ text: "Okay", value: "" }]
    ).then(() => {
      // Redirect to the home page
      this.$router.push("/home");
    });
  }

  onInputUpdate(
    name: "name" | "company_name" | "job_type" | "external_link",
    inputEvent: InputEvent
  ) {
    const inputEl = inputEvent.target as HTMLInputElement;
    this.formData[name] = inputEl.value;
  }

  onDateChange(name: "application_deadline", date: any) {
    this.formData[name] = date;
  }

  onCheckBoxChange(name: "is_remote", inputEvent: InputEvent) {
    const inputEl = inputEvent.target as HTMLInputElement;
    this.formData[name] = inputEl.checked;
  }

  onAddNewTag(tag: string) {
    const tags = this.formData.tags || [];
    if (!tags.includes(tag)) tags.push(tag);
    this.formData.tags = tags;

    this.$forceUpdate();
  }

  onRemoveTag(tag: string) {
    const tags = this.formData.tags || [];
    const index = tags.indexOf(tag);
    if (index >= 0) tags.splice(index, 1);
    this.formData.tags = tags;

    this.$forceUpdate();
  }
}
