<template>
  <ion-app>
    <div class="root-container">
      <div class="left-slider" ref="leftSlider" ismenuopen="false">
        <div class="menu-container"><LeftSideMenu /></div>
      </div>
      <div class="main-content">
        <router-view v-slot="{ Component }">
          <keep-alive>
            <component :is="Component" />
          </keep-alive>
        </router-view>
      </div>
    </div>
  </ion-app>
</template>
<style>
@import "../assets/css/global.css";
</style>

<style scoped>
.root-container {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.left-slider {
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: var(--theme-color14);
}

.left-slider[ismenuopen="true"] {
  left: 0;
}

.left-slider[ismenuopen="false"] {
  left: -100%;
}

.menu-container {
  width: 75%;
  max-width: 350px;
  height: 100%;
}

.main-content {
  overflow: auto;
  height: 100%;
}
.scroll-container[ismenuopen="true"] {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}
</style>

<script>
import component from "./App.component";
export default component;
</script>
