import { Vue, Options } from "vue-class-component";

import FormField from "../FormField";

import { JobType } from "../../models";
import * as $filters from "../../filters";

interface SearchParams {
  q?: string;
  job_type?: string;
  is_remote?: boolean;
}

@Options({
  components: { FormField },
  props: {
    inputDebouce: {
      type: Number,
      default: 500,
    },
  },
})
export default class ResearchJobSearchFilter extends Vue {
  jobTypeOptions = [
    { name: "Any job type", value: "" },
    { name: "Full time", value: JobType.full_time },
    { name: "Part time", value: JobType.part_time },
    { name: "Contract", value: JobType.contract },
    { name: "Internship", value: JobType.internship },
  ];

  searchParams: SearchParams = {};

  timerId?: number;

  inputDebouce?: number;

  throttleInput(name: "q", $event: Event) {
    clearTimeout(this.timerId);
    this.timerId = setTimeout(
      () => this.onInputChange(name, $event),
      this.inputDebouce
    );
  }

  onInputChange(name: "q" | "job_type" | "is_remote", $event: Event) {
    const inputEl = $event.target as HTMLInputElement;
    const value =
      inputEl.type === "checkbox" ? inputEl.checked : (inputEl.value as any);

    if (value) this.searchParams[name] = value;
    else this.searchParams[name] = undefined;

    this.$emit("paramsChange", { ...this.searchParams });
  }
}
