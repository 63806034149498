<template>
  <div class="form-pages-root">
    <Header />

    <div class="main-content-scroller">
      <div class="main-content">
        <img class="main-image" src="/assets/icons/ideas.svg" />
        <div class="side-content">
          <div class="title">Welcome to PeeR EDGE</div>
          <div class="subtitle">Share with millions of researchers online.</div>

          <img src="/assets/ccircle.png" height="400" width="700" />

          <p>Select from your connections.</p>
        </div>
      </div>

      <img class="bg-1" src="/assets/icons/eclipse7.svg" />
      <img class="bg-2" src="/assets/icons/eclipse8.svg" />
      <Footer />
    </div>
  </div>
</template>

<script>
import "vue3-circle-progress/dist/circle-progress.css";
import component from "./component";
import VueElementLoading from "vue-element-loading";
export default component;
</script>

<style scoped>
.main-content {
  min-height: 100%;
}

.form-pages-root .bg-1 {
  position: absolute;
  bottom: -12rem;
  right: -10rem;
  z-index: -1;
}

.form-pages-root .bg-2 {
  position: absolute;
  width: 12rem;
  height: auto;
  left: -6rem;
  bottom: -6.5rem;
  opacity: 0.2;
  z-index: -1;
}

.title {
  position: relative;
  margin-left: 120px;
}

.subtitle {
  position: relative;
  margin-left: 160px;
  padding-bottom: 40px;
  color: #1f1660;
}
.title sup {
  position: absolute;
  font-size: 0.9rem;
  top: 0.5rem;
}
</style>
<style scoped>
p {
  font-size: 1.1em;
  font-weight: 50;
  margin-top: 40px;
  letter-spacing: 1px;
  color: #01a2e0;
  margin-left: 130px;
}

.progress {
  margin-top: 90px;
  position: relative;
  margin-left: 70px;
}
</style>
