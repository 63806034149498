import { Vue, Options } from "vue-class-component";

import MemberSearchListItem from "../../components/MemberSearchListItem";
import MemberSearchFilter from "../../components/MemberSearchFilter";
import Notifications from "../../components/Notifications";
import { MemberApi } from "../../services/http";
import { Events, EventTypes, calcAlternativeIndex } from "../../services/utils";
import { Member, CONNECT_STATUS_TYPE, CONNECT_ACTIONS } from "../../models";
import LeftSideMenu from "@/components/LeftSideMenu/component";
@Options({
  components: {
    MemberSearchListItem,
    MemberSearchFilter,
    LeftSideMenu,
  },
})
export default class MemberSearchView extends Vue {
  resultsData: any;
  hasMoreResults = true;
  searchParams = {} as any;
  page = 0;

  calcAlternativeIndex = calcAlternativeIndex;

  created() {
    this.searchParamsChanged(this.searchParams);
  }

  mounted() {
    Events.on(EventTypes.memberUpdate, this.onMemberUpdate);
  }

  beforeUnmount() {
    Events.off(EventTypes.memberUpdate, this.onMemberUpdate);
  }

  async getMembers(page: number, params: any, reset = false) {
    const data = await MemberApi.search({ ...params, page });
    let results = this.resultsData ? this.resultsData.results : [];
    if (reset) results = [];
    results.push(...data.results);

    this.resultsData = Object.assign(this.resultsData || {}, {
      ...data,
      results,
    });

    if (data.results && data.results.length > 0) this.page = page;

    this.hasMoreResults = !!data.next;

    if (reset) {
      const scrollElm = this.getScrollElement();
      if (scrollElm) scrollElm.scrollTop = 0;
    }

    this.$forceUpdate();
  }

  getScrollElement(): HTMLElement | null {
    return this.$refs["scrollContainer"] as HTMLElement;
  }

  async loadMoreResults(event: Event) {
    const scrollElm = this.getScrollElement()!;
    const scrollToBottomLength =
      scrollElm.scrollHeight - scrollElm.offsetHeight - scrollElm.scrollTop;
    const atBottom = scrollToBottomLength < 3;

    if (atBottom && this.hasMoreResults)
      this.getMembers(this.page + 1, this.searchParams);
  }

  async searchParamsChanged(params: any) {
    this.searchParams = params;
    this.getMembers(1, params, true);
  }

  async onConnectStatusUpdated(actionType: CONNECT_ACTIONS, member: Member) {
    const results = (this.resultsData?.results || []) as Member[];
    const existing = results.find((m) => m.id === member.id);
    if (existing) Object.assign(existing, { ...member });

    Events.emit(EventTypes.connectionStatusUpdated, member);

    let message = "";
    switch (actionType) {
      case CONNECT_ACTIONS.accept:
        message = `You are now connected to ${member.name}`;
        break;
      case CONNECT_ACTIONS.connect:
        message = `Connection request sent to ${member.name}`;
        break;
      case CONNECT_ACTIONS.cancel:
        message = `Your connection request has been withdrawn`;
        break;

      case CONNECT_ACTIONS.remove:
        message = `This connection has been removed`;
        break;

      case CONNECT_ACTIONS.decline:
        message = `You have declined this connection request`;
        break;
    }

    if (message) Notifications.toast(message);
  }

  onMemberUpdate(event: CustomEvent) {
    const member = event.detail as Member;
    const results = (this.resultsData?.results || []) as Member[];
    const existing = results.find((m) => m.id === member.id);

    if (existing) {
      const index = results.indexOf(existing);
      const newUpdated = Object.assign({}, existing, member);
      results[index] = newUpdated;
      this.$forceUpdate();
    }
  }
}
