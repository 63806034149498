export enum GA_ACTIONS {
  view_profile = "view_profile",
  view_own_profile = "view_own_profile",
  edit_profile = "edit_profile",
  save_profile = "save_profile",
  message_member = "message_member",
  make_connection_request = "make_connection_request",
  accept_connection = "accept_connection",
  decline_connection = "decline_connection",
  remove_connection = "remove_connection",
  cancel_connection = "cancel_connection",
  view_job = "view_job",
  view_conference = "view_conference",
  view_community = "view_community",
  view_feed = "view_feed",
  view_grant = "view_grant",
  view_library = "view_library",
  view_comment = "view_comment",
}

export enum GA_CATEGORIES {
  profiles = "profiles",
  messaging = "messaging",
  connections = "connections",
  jobs = "jobs",
  conferences = "conferences",
  communities = "communities",
  feeds = "feeds",
  grants = "grants",
  libraries = "libraries",
  comments = "comments",
}
