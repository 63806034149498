import moment from "moment";
import { Preference, RELATIONSHIP_POSITIONS } from "../models";

import { TimezoneOptions } from "../services/timezones";

export function InterestsText(interests?: string[], spacer?: string): string {
  if (!interests || interests.length === 0) return "N/A";

  return interests.join(spacer || ", ");
}

export function TimeZoneText(timezoneId?: string): string {
  const timezone = TimezoneOptions.find((t) => t.value === timezoneId);

  return timezone?.name || timezoneId || "N/A";
}

export function TimeZonesText(timezones?: string[], spacer?: string): string {
  if (!timezones) return "N/A";

  return timezones.map((t) => TimeZoneText(t)).join(spacer || "<br>");
}

export function CollaborationText(research_preference: Preference): string {
  let text = "";
  if (research_preference.peer_to_peer === true) text = "Peer to peer";

  if (
    research_preference.relationship_position === RELATIONSHIP_POSITIONS.mentee
  )
    text += `${text ? ", " : ""}Looking for an advisor`;
  else if (
    research_preference.relationship_position === RELATIONSHIP_POSITIONS.mentor
  )
    text += `${text ? ", " : ""}Interested in advising`;

  return text;
}

export function RelationshipPositionText(
  position?: RELATIONSHIP_POSITIONS
): string {
  switch (position) {
    case RELATIONSHIP_POSITIONS.mentor:
      return "Interested in advising";

    case RELATIONSHIP_POSITIONS.mentee:
      return "Looking for an advisor";

    default:
      return "N/A";
  }
}

export function DateFormatDisplay(date?: string): string {
  if (!date) return "N/A";

  const mDate = moment(date);
  return mDate.format("MMM Do, YYYY");
}

export function AvailabilityText(
  preference: Preference,
  startPrefix?: string,
  endPrefix?: string
): string {
  if (preference.availability_start) {
    let title = `${startPrefix || ""}${DateFormatDisplay(
      preference.availability_start
    )}`;
    if (preference.availability_end)
      title = `${title} ${endPrefix || "-"} ${DateFormatDisplay(
        preference.availability_end
      )}`;

    return title;
  } else return "N/A";
}

export function GroupSizeText(groupSize?: number): string {
  if (groupSize === null || groupSize === undefined) return "N/A";

  if (groupSize === 0) return "no one";

  return `${groupSize} ${groupSize > 1 ? "people" : "person"}`;
}

export function ProjectRunningText(isRunning?: boolean): string {
  if (isRunning === null || isRunning === undefined) return "N/A";

  return isRunning === true ? "Yes" : "No";
}

export function ProjectProgressText(progress?: number | null): string {
  if (progress === null || progress === undefined) return "N/A";

  return `${progress}%`;
}

export function AuthorshipText(isMain?: boolean): string {
  if (isMain === null || isMain === undefined) return "N/A";

  return isMain === true ? "main author" : "co-author";
}

export function SkillLevel(level?: string): string {
  if (level === null || level === undefined) return "N/A";

  return level;
}

export function CountText(
  value?: number,
  max?: number,
  empty?: string
): string {
  if (value === null || value === undefined) {
    if (empty === null || empty === undefined) return "N/A";
    else return empty;
  }

  if (max === null || max === undefined || value < max) return value.toString();

  return `${max}+`;
}

export const filters = {
  InterestsText,
  TimeZoneText,
  TimeZonesText,
  RelationshipPositionText,
  DateFormatDisplay,
  AvailabilityText,
  GroupSizeText,
  ProjectRunningText,
  ProjectProgressText,
  AuthorshipText,
  CountText,
  SkillLevel,
  CollaborationText,
};
