import { RouteLocationNormalized, NavigationGuardNext } from "vue-router";
import { AuthApi, ProfileApi, MemberApi } from "../services/http";
import Notifications from "@/components/Notifications";

import router from "../index/routes";

export async function AuthRedirect(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const isLoggedIn = await AuthApi.isLoggedIn();

  const redirect = (<any>to).href;

  if (!isLoggedIn) window.location.href = `/login?redirect=${redirect}`;
  else next();
}

export async function AuthGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const isLoggedIn = await AuthApi.isLoggedIn();

  const redirect = (<any>to).href;

  if (!isLoggedIn) window.location.href = `/login?redirect=${redirect}`;
  else {
    const member = await AuthApi.member!;

    if (
      member.prompted_for_profile_completion === false &&
      !redirect.startsWith("/profile/edit")
    ) {
      router.push(`/profile/edit?redirect=${redirect}`);
    }

    to.meta.member = member;
    next();
  }
}

export async function AuthGuardForOtherMember(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const isLoggedIn = await AuthApi.isLoggedIn();

  const redirect = (<any>to).href;

  if (!isLoggedIn) window.location.href = `/login?redirect=${redirect}`;
  else {
    const member = await MemberApi.getProfile(to.params.id as string);

    if (member.public_id != to.params.id) {
      router.replace(`/profile/${member.public_id}`);
      return;
    }

    to.meta.member = member;
    if (member.hide_profile) {
      const message = `This user has chosen to keep their profile hidden`;
      await Notifications.toast(message);
      next(false);
    } else {
      next();
    }
  }
}

export async function AlreadyLoggedInRedirect(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  if (to.query["skip_redirect"] === "true") {
    return next();
  }

  const isLoggedIn = await AuthApi.isLoggedIn();

  const redirect = (to.query.redirect || "/home") as string;

  if (isLoggedIn) window.location.href = redirect;
  else next();
}

export async function LogoutRedirect(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  await AuthApi.logout();
  window.location.href = "/";
}
