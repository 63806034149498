import { Vue, Options } from "vue-class-component";
import { PropType } from "vue";
import moment from "moment";

import { Events, EventTypes, gaEvent } from "../../services/utils";
import { AuthApi, ProfileApi } from "../../services/http";
import {
  Member,
  CONNECT_ACTIONS,
  CONNECT_STATUS_TYPE,
  GA_ACTIONS,
  GA_CATEGORIES,
  RELATIONSHIP_POSITIONS,
} from "../../models";
import { TimezoneOptions } from "../../services/timezones";
import { filters } from "../../filters";
import Notifications from "../Notifications";
import FormField from "../FormField";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";

@Options({
  components: {
    FormField,
    VueSelect,
  },
  props: {
    user: Object as PropType<Member>,
    title: String,
    subtitle: String,
    dismissable: Boolean,
    createAccount: Boolean,
    onProfileSave: Function,
    onDismiss: Function,
    isModal: Boolean,
    dismissBtnLabel: String,
    saveBtnLabel: String,
  },
})
export default class CompleteProfile extends Vue {
  TimezoneOptions = [
    { name: "Select timezone", value: "" },
    ...TimezoneOptions,
  ];

  relationshipPositionOptions = [
    {
      name: "N/A",
      value: "",
    },
    {
      name: filters.RelationshipPositionText(RELATIONSHIP_POSITIONS.mentor),
      value: RELATIONSHIP_POSITIONS.mentor,
    },
    {
      name: filters.RelationshipPositionText(RELATIONSHIP_POSITIONS.mentee),
      value: RELATIONSHIP_POSITIONS.mentee,
    },
  ];

  user!: Member;
  member!: Member;
  title!: string;
  subtitle!: string;
  dismissable = false;
  createAccount = false;
  isModal = false;
  onProfileSave?: () => void;
  onDismiss?: () => void;
  dismissBtnLabel = "Later";
  saveBtnLabel = "Save profile";
  private selectedTimezone: string | null = null;

  created() {
    this.member = JSON.parse(JSON.stringify(this.user));

    this.member.research_preference = this.member.research_preference || {
      timezones: [],
      interests: [],
      peer_to_peer: true,
    };

    if (!this.member.research_preference.peer_to_peer)
      this.member.research_preference.peer_to_peer = true;
  }

  onPreferenceUpdate(field: string, value: any) {
    (this.member.research_preference as any)[field] = value;

    if (field === "relationship_position" && !value) {
      this.member.research_preference.peer_to_peer = true;
    }

    this.$forceUpdate();
  }

  onMemberUpdate(field: string, value: any) {
    (this.member as any)[field] = value;
    this.$forceUpdate();
  }

  onAddNewInterest(interest: string) {
    const interests = this.member.research_preference.interests || [];
    if (!interests.includes(interest)) interests.push(interest);
    this.member.research_preference.interests = interests;

    this.$forceUpdate();
  }

  onRemoveInterest(interest: string) {
    const interests = this.member.research_preference.interests || [];
    const index = interests.indexOf(interest);
    if (index >= 0) interests.splice(index, 1);
    this.member.research_preference.interests = interests;

    this.$forceUpdate();
  }

  onAddNewTimeZone(selectedOption: any) {
    if (selectedOption && selectedOption.value) {
      const timezone = selectedOption.value;
      const timezones = this.member.research_preference.timezones || [];

      if (!timezones.includes(timezone)) {
        timezones.push(timezone);
        this.member.research_preference.timezones = timezones;
        this.selectedTimezone = null; // Clear the selected timezone
        this.$forceUpdate();
      }
    }
  }

  onRemoveTimezone(timezone: string) {
    const timezones = this.member.research_preference.timezones || [];
    const index = timezones.indexOf(timezone);
    if (index >= 0) timezones.splice(index, 1);
    this.member.research_preference.timezones = timezones;

    this.$forceUpdate();
  }

  async onFormSubmit($event: Event) {
    $event.stopPropagation();
    $event.preventDefault();
    $event.stopImmediatePropagation();
    this.member.research_preference.skills =
      this.member.research_preference.skills || [];
    this.member.research_preference.sought_skills =
      this.member.research_preference.sought_skills || [];

    const newUpdated = await (!this.createAccount
      ? ProfileApi.updateProfile(this.member)
      : AuthApi.createAccount(this.member));

    gaEvent(GA_ACTIONS.save_profile, GA_CATEGORIES.profiles);

    Object.assign(this.member, newUpdated);

    Events.emit(EventTypes.profileUpdate, this.member);

    this.$emit("profileCompleted", newUpdated);

    if (this.onProfileSave) this.onProfileSave();
  }

  onDismissClick($event: Event) {
    $event.stopPropagation();
    $event.preventDefault();
    $event.stopImmediatePropagation();

    this.$emit("dimissed");

    if (this.onDismiss) this.onDismiss();
  }
}
