import { Vue, Options } from "vue-class-component";

import {
  Member,
  CONNECT_STATUS_TYPE,
  CONNECT_ACTIONS,
  GA_ACTIONS,
  GA_CATEGORIES,
} from "../../models";
import { ProfileApi, MemberApi, AuthApi } from "../../services/http";
import { MemberListActions, MemberConnectActions } from "../../services/data";
import { Events, EventTypes, gaEvent } from "../../services/utils";
import { PromptProfileCompletion } from "../../services/prompt-profile-completion";
import { filters } from "../../filters";

import Notifications from "../../components/Notifications";
import FormField from "../../components/FormField";

import { SkillColor } from "../../services/data";

@Options({
  components: {
    FormField,
  },
})
export default class FAQs extends Vue {}
