import { Vue, Options } from "vue-class-component";
import { PropType } from "vue";
import moment from "moment";
import axios from "axios";
import { Feeds, FeedsComment } from "../../models";
import {
  FeedsApi,
  FeedsCommentApi,
  FeedsLikesAPI,
  FeedsdisLikesAPI,
} from "../../services/http";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Sharing from "@/components/ShareDetails/component";
import LeftSideMenu from "../../components/LeftSideMenu";
import FeedsListItem from "../../components/FeedsListItem";
import FormField from "../../components/FormField";
import Share from "../ShareDetails/component";

@Options({
  props: {
    feedId: { type: Number, required: true },
  },
  components: {
    LeftSideMenu,
    FeedsListItem,
    FormField,
  },
})
export default class FeedDetails extends Vue {
  feed: Feeds = {} as Feeds;
  timeSincePost = "";
  liked = false;
  disliked = false;
  likeCount = 0;
  dislikeCount = 0;
  newComment = "";
  comments: FeedsComment[] = [];

  async created() {
    const feedId = this.$route.params.feedId;
    this.feed = await FeedsApi.getFeed(feedId);
    const resp = await FeedsCommentApi.list(+feedId);
    this.comments = resp.comments;
    console.log("this.comments:", this.comments);
  }

  getTimeSinceComment(time: any) {
    return moment(time).fromNow();
  }

  async getFeed() {
    try {
      const feedId = this.$route.params.feedId;
      this.feed = await FeedsApi.getFeed(feedId);
      this.timeSincePost = moment(this.feed.created).fromNow();
      this.getLikes();
      //this.getdisLikes();
    } catch (error) {
      console.error(error);
    }
  }

  async like() {
    try {
      if (this.liked) {
        this.liked = false;
        this.likeCount--;
      } else {
        await FeedsLikesAPI.like(this.feed.id, true);

        this.liked = true;
        this.likeCount++;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getLikes() {
    try {
      const response = await FeedsLikesAPI.getLikes(this.feed.id);
      console.log("----:", response);
      //const response = await axios.get(
      //  `http://localhost:8000/api/feeds/${this.feed.id}/likes`
      //);
      //this.likeCount = response.data.total_likes;
      //this.likeCount = response.total_likes;
    } catch (error) {
      console.error(error);
    }
  }

  async dislike() {
    try {
      if (this.disliked) {
        this.disliked = false;
        this.dislikeCount--;
      } else {
        await FeedsdisLikesAPI.dislike(this.feed.id, true);

        this.disliked = true;
        this.dislikeCount++;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getdisLikes() {
    try {
      const response = await FeedsdisLikesAPI.getdisLikes(this.feed.id);
      console.log("----:", response);
    } catch (error) {
      console.error(error);
    }
  }

  async postComment() {
    if (this.feed.id === undefined) return;

    await FeedsCommentApi.add(this.feed.id, {
      text: this.newComment,
    });

    const resp = await FeedsCommentApi.list(this.feed.id);
    this.comments = resp.comments;
  }

  async getComments() {
    try {
      if (this.feed.id === undefined) return;
      this.comments = await FeedsCommentApi.list(this.feed.id);
    } catch (error) {
      console.error(error);
    }
  }
}
