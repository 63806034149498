import { Vue, Options } from "vue-class-component";
import ConversationWindow from "../../components/ConversationWindow";
import LeftSideMenu from "../../components/LeftSideMenu";
import { ConversationOptions } from "../../components/ConversationWindow";
import MessagesHeader from "../../components/MessagesHeader";

@Options({
  components: {
    ConversationWindow,
    LeftSideMenu,
    MessagesHeader,
  },
})
export default class Messages extends Vue {
  conversationOption: ConversationOptions | null = null;

  async created() {
    this.setConversation(this.$route.path);
    this.$watch("$route", (to: any) => {
      this.setConversation(to.path);
      this.$forceUpdate();
    });
  }

  setConversation(path: string) {
    let id = null;

    if (path.startsWith("/messages/room")) {
      id = this.$route.query["rid"];
    } else if (path.startsWith("/messages")) {
      id = this.$route.params.id;
    }

    if (id) this.conversationOption = { conversation: { id } };
    else this.conversationOption = null;
  }
}
