import {
  loadingController,
  alertController,
  toastController,
} from "@ionic/vue";

interface AlertOptions {
  variant?: "warning" | "error" | "success" | "default";
  title?: string;
  message?: string;
  backdropDismiss?: boolean;
}

interface ToastOptions extends AlertOptions {
  duration?: number;
  position?: string;
}

interface ActionOptions {
  text: string;
  value: any;
  cssClass?: string;
}

export default class Notifications {
  static async alert<T>(
    options: AlertOptions | string,
    actions?: ActionOptions[]
  ): Promise<T | undefined> {
    return this._notify("alert", options, actions);
  }

  static async toast<T>(
    options: ToastOptions | string,
    actions?: ActionOptions[]
  ): Promise<T | undefined> {
    return this._notify("toast", options, actions);
  }

  static async loader<T>(
    _params: any
  ): Promise<HTMLIonLoadingElement | undefined> {
    try {
      const params = { ..._params };
      params.cssClass = params.cssClass || "loader-custom-class";
      const loader = await loadingController.create(params);
      loader.present();
      return loader;
    } catch (exception) {
      console.log(exception);
    }
  }

  private static async _notify<T>(
    type: "alert" | "toast",
    options: AlertOptions | string,
    actions?: ActionOptions[]
  ): Promise<T | undefined> {
    return new Promise((res, rej) => {
      const aOptions =
        typeof options === "object" ? { ...options } : { message: options };
      const buttons = (actions || []).map((action) => ({
        text: action.text,
        cssClass: `notification-alert-btn ${action.cssClass || ""}`,
        handler: () => res(action.value),
      }));

      const alertOptions = { ...aOptions } as any;

      alertOptions.header = aOptions.title;
      alertOptions.message = aOptions.message;

      let cssClass = "notification-alert";

      switch (aOptions.variant) {
        case "warning":
        case "error":
        case "success":
        default:
          cssClass += "";
      }
      alertOptions.cssClass = cssClass;

      alertOptions.buttons = buttons;
      alertOptions.backdropDismiss = aOptions.backdropDismiss;

      const caller = type === "alert" ? presentAlert : presentToast;

      caller(alertOptions).then((alert: any) => {
        if (!alert) res(undefined);
        else alert.onDidDismiss().then(() => res(undefined));
      });
    });
  }
}

async function presentAlert(options: any): Promise<any> {
  const alert = await alertController.create(options);
  try {
    await alert.present();
    return alert;
  } catch (e) {
    console.log(e);
  }
}

async function presentToast(options: any): Promise<any> {
  const buttons =
    options.buttons?.length > 0
      ? options.buttons
      : [{ text: "Okay", handler: () => toast.dismiss() }];

  const toast = await toastController.create({
    duration: 1000,
    position: "top",
    ...options,
    buttons,
    cssClass: "notification-toast",
  });

  try {
    await toast.present();
    return toast;
  } catch (e) {
    console.log(e);
  }
}
