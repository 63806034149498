import axios from "axios";
import Notifications from "../components/Notifications";
import { RocketChatCA2LoginToken } from "./rocket-chat";

export class AuthTokenHandler {
  private static _token?: string | null;

  static get token(): string | null {
    if (this._token === undefined)
      this._token = localStorage.getItem("auth-token");

    return this._token;
  }

  static set token(value: string | null) {
    if (value) {
      localStorage.setItem("auth-token", value);
    } else localStorage.removeItem("auth-token");

    this._token = value;
    RocketChatCA2LoginToken(value);
  }
}

let loaderCount = 0;
let loaderPrm: Promise<any>;

const startLoader = (config: any) => {
  if (loaderCount === 0) {
    loaderPrm = Notifications.loader({
      showBackdrop: false,
      backdropDismiss: false,
    });
  }
  loaderCount++;
};

const endLoader = async (config: any) => {
  loaderCount--;
  if (loaderCount === 0) {
    const loader = await loaderPrm;
    loader.dismiss();
  }
};

export function requestInterceptor(config: any): any {
  startLoader(config);

  if (AuthTokenHandler.token)
    config.headers["Authorization"] = `Token ${AuthTokenHandler.token}`;
  return config;
}

export function responseInterceptor(response: any): any {
  endLoader(response?.config);

  if (response.headers["set-auth-token"])
    AuthTokenHandler.token = response.headers["set-auth-token"];

  return response.data;
}

export function responseErrorInterceptor(error: any) {
  endLoader(error?.config);

  const response = error.response;
  let errorMessage = "";

  if (response) {
    if (response.status === 401) {
      AuthTokenHandler.token = null;
    } else {
      if (typeof response.data === "string") {
        errorMessage = `${response.status}: ${response.statusText}`;
      } else {
        errorMessage = getErrorMessage(response.data);
      }
    }
  } else {
    errorMessage = error.message;
  }

  if (errorMessage)
    Notifications.alert(errorMessage, [{ text: "Okay", value: "" }]);

  return Promise.reject(error);
}

function getErrorMessage(error: any): string {
  if (
    typeof error === "string" ||
    typeof error === "boolean" ||
    typeof error === "number"
  ) {
    return String(error);
  } else if (typeof error === "object" && Array.isArray(error)) {
    return error.map((e) => getErrorMessage(e)).join(",");
  } else if (typeof error === "object") {
    return Object.keys(error)
      .map((key) =>
        key === "non_field_errors"
          ? getErrorMessage(error[key])
          : `${key}: ${getErrorMessage(error[key])}`
      )
      .join(",");
  } else {
    return String(error);
  }
}
