import { Vue, Options } from "vue-class-component";

import Footer from "../../components/Footer";

@Options({
  components: {
    Footer,
  },
})
export default class LandingPage extends Vue {
  private services = [
    {
      title: "Find collaborators",
      icon: "/assets/icons/search.svg",
      description:
        "Share knowledge, build onto ideas, collaborate with peers, and mentor the next generation",
    },
    {
      title: "Find research jobs",
      icon: "/assets/icons/connect.svg",
      description:
        "Search for and advertise new job positions within your area of interest, and level of expertise",
    },
    {
      title: "Conferences & communities",
      icon: "/assets/icons/collaborate.svg",
      description:
        "Search for communities, conferences & workshops within your interests",
    },
  ];
}
