import { Vue, Options } from "vue-class-component";

import FormField from "../FormField";
import * as $filters from "../../filters";

interface SearchParams {
  q?: string;
}

@Options({
  components: { FormField },
  props: {
    inputDebouce: {
      type: Number,
      default: 500,
    },
  },
})
export default class ResearchCommunitySearchFilter extends Vue {
  searchParams: SearchParams = {};

  timerId?: number;

  inputDebouce?: number;

  throttleInput(name: "q", $event: Event) {
    clearTimeout(this.timerId);
    this.timerId = setTimeout(
      () => this.onInputChange(name, $event),
      this.inputDebouce
    );
  }

  onInputChange(name: "q", $event: Event) {
    const inputEl = $event.target as HTMLInputElement;
    const value =
      inputEl.type === "checkbox" ? inputEl.checked : (inputEl.value as any);

    if (value) this.searchParams[name] = value;
    else this.searchParams[name] = undefined;

    this.$emit("paramsChange", { ...this.searchParams });
  }
}
