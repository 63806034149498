import { Vue, Options } from "vue-class-component";
import { FeedsApi, ResearchCommunityApi } from "../../services/http";
import Notifications from "../../components/Notifications";
import FormField from "../../components/FormField";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Sharing from "@/components/ShareDetails/component";

import { JobType } from "../../models";

@Options({
  components: {
    FormField,
    Header,
    Footer,
  },
})
export default class AddFeed extends Vue {
  formData = {
    text: "",
    created_by: "",
  };

  getRedirectUrl(): string | undefined {
    const query = this.$route.query;
    if (query && query.redirect) return query.redirect as string;
  }

  async onFormSubmit($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
    $event.stopImmediatePropagation();

    const form = this.$refs["form"] as HTMLFormElement;
    //const logo = form.querySelector(`input[name="logo"]`)! as HTMLInputElement;
    const formData = new FormData();
    Object.keys(this.formData).forEach((key) => {
      formData.set(key, (this.formData as any)[key]);
    });

    //formData.set("logo", logo.files!.item(0)!);

    const job = await FeedsApi.add(formData);

    Notifications.alert(
      "Thanks for this posting, this will be reviewed by PeeREDGE and added to the platform in the next few days",
      [{ text: "Okay", value: "" }]
    ).then(() => {
      // Redirect to the home page
      this.$router.push("/home");
    });
  }

  onInputUpdate(name: "text" | "created_by", inputEvent: InputEvent) {
    const inputEl = inputEvent.target as HTMLInputElement;
    this.formData[name] = inputEl.value;
  }
}
