import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import LoginVue from "../pages/Login";
import ShiboLoginVue from "../pages/ShiboLogin";
import Share from "../pages/ShareDetails";
import GetAccessVue from "../pages/GetAccess";
import FAQsVue from "../pages/FAQs";
import CreateAccountVue from "../pages/CreateAccount";
import HomeVue from "../pages/Home";
import ResearchJobsVue from "../pages/ResearchJobs";
import ResearchGrantsVue from "../pages/ResearchGrants";
import ResearchConferencesVue from "../pages/ResearchConferences";
import ResearchCommunitiesVue from "../pages/ResearchCommunities";
import ResearchLibrariesVue from "../pages/ResearchLibraries";
import FeedsVue from "../pages/Feeds";
import FeedDetailsVue from "../pages/FeedDetails";
import MessagesVue from "../pages/Messages";
import LandingPage from "../pages/Landing";
import ConnectionsPage from "../pages/Connections";
import ConnectionRequestsPage from "../pages/ConnectionRequests";
import ProflePage from "../pages/Profle";
import ProfileEditPage from "../pages/ProfileEdit";
import ForgotPasswordPage from "../pages/ForgotPassword";
import ResetPasswordPage from "../pages/ResetPassword";
import AddResearchJobPage from "../pages/AddResearchJob";
import AddResearchGrantPage from "../pages/AddResearchGrant";
import AddResearchCommunityPage from "../pages/AddResearchCommunity";
import AddResearchLibraryPage from "../pages/AddResearchLibrary";
import AddFeedPage from "../pages/AddFeed";
import AddResearchConferencePage from "../pages/AddResearchConference";
import ConnectionNetworkVue from "../components/MemberConnections";
import ConnectionReceivedRequestsVue from "../components/MemberReceivedRequests";
import ConnectionSentRequestsVue from "../components/MemberSentRequests";

import {
  AlreadyLoggedInRedirect,
  LogoutRedirect,
  AuthGuard,
  AuthGuardForOtherMember,
} from "../guards";

import { gaPageView } from "../services/utils";

declare const window: any;

export const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    component: LandingPage,
    beforeEnter: AlreadyLoggedInRedirect,
    meta: { title: "PeerEdge | Collaborate With Researchers Anytime Anywhere" },
  },
  {
    path: "/login",
    component: LoginVue,
    beforeEnter: AlreadyLoggedInRedirect,
    meta: { title: "PeerEdge | Login" },
  },

  {
    path: "/shibologin",
    name: "shibologin",
    component: ShiboLoginVue,
    beforeEnter: AlreadyLoggedInRedirect,
    props: (route) => ({ query: route.query.token }),
    meta: { title: "PeerEdge | ShiboLogin" },
  },

  {
    path: "/share",
    name: "share",
    component: Share,
    meta: { title: "PeerEdge | Share" },
  },

  {
    path: "/get-access",
    component: GetAccessVue,
    beforeEnter: AlreadyLoggedInRedirect,
    meta: { title: "PeerEdge  | Get Access" },
  },

  {
    path: "/create-account",
    component: CreateAccountVue,
    beforeEnter: AlreadyLoggedInRedirect,
    meta: { title: "PeerEdge  | Create Account" },
  },

  {
    path: "/forgot-password",
    component: ForgotPasswordPage,
    meta: { title: "PeerEdge  | Forgot password" },
  },

  {
    path: "/reset-password/:token",
    component: ResetPasswordPage,
    meta: { title: "PeerEdge  | Reset password" },
  },

  {
    path: "/home",
    component: HomeVue,
    beforeEnter: AuthGuard,
    meta: { title: "PeerEdge  | Find researchers/collaborators/advisors" },
  },

  {
    path: "/feeds",
    component: FeedsVue,
    beforeEnter: AuthGuard,
    meta: { title: "PeerEdge  | View Feeds" },
  },
  {
    path: "/add-feed",
    component: AddFeedPage,
    meta: { title: "PeerEdge  | Add Feed" },
  },
  {
    path: "/feeds/:feedId",
    component: FeedDetailsVue,
    meta: { title: "PeerEdge  | Comments" },
    name: "FeedDetails",
  },

  {
    path: "/jobs",
    component: ResearchJobsVue,
    beforeEnter: AuthGuard,
    meta: { title: "PeerEdge  | Find Research jobs" },
  },

  {
    path: "/add-research-job",
    component: AddResearchJobPage,
    meta: { title: "PeerEdge  | Add Research jobs" },
  },
  {
    path: "/grants",
    component: ResearchGrantsVue,
    beforeEnter: AuthGuard,
    meta: { title: "PeerEdge  | Find Research grants" },
  },
  {
    path: "/add-research-grant",
    component: AddResearchGrantPage,
    meta: { title: "PeerEdge  | Add Research grants" },
  },
  {
    path: "/add-research-community",
    component: AddResearchCommunityPage,
    meta: { title: "PeerEdge  | Add Research communities" },
  },
  {
    path: "/add-research-library",
    component: AddResearchLibraryPage,
    meta: { title: "PeerEdge  | Add Reseach libraries" },
  },
  {
    path: "/add-research-conference",
    component: AddResearchConferencePage,
    meta: { title: "PeerEdge  | Add Research conferences" },
  },
  {
    path: "/communities",
    component: ResearchCommunitiesVue,
    beforeEnter: AuthGuard,
    meta: { title: "PeerEdge  | Find Research communities" },
  },
  {
    path: "/libraries",
    component: ResearchLibrariesVue,
    beforeEnter: AuthGuard,
    meta: { title: "PeerEdge  | Find Reseach libraries" },
  },
  {
    path: "/conferences",
    component: ResearchConferencesVue,
    beforeEnter: AuthGuard,
    meta: { title: "PeerEdge  | Find Research conferences" },
  },
  {
    path: "/faq",
    component: FAQsVue,
    beforeEnter: AuthGuard,
    meta: { title: "PeerEdge  | FAQs" },
  },

  {
    path: "/messages",
    component: MessagesVue,
    beforeEnter: AuthGuard,
    meta: { title: "PeerEdge  | Messages" },
  },
  {
    path: "/messages/room",
    component: MessagesVue,
    beforeEnter: AuthGuard,
    meta: { title: "PeerEdge  | Messages" },
  },
  {
    path: "/messages/:id",
    component: MessagesVue,
    beforeEnter: AuthGuard,
    meta: { title: "PeerEdge  | Messages" },
  },
  {
    path: "/connections",
    beforeEnter: AuthGuard,
    component: ConnectionsPage,
    children: [
      {
        path: "network",
        component: ConnectionNetworkVue,
        meta: { title: "PeerEdge  | Your network" },
      },
      {
        path: "requests",
        component: ConnectionRequestsPage,
        meta: { title: "PeerEdge  | Connection requests" },
        children: [
          {
            path: "sent",
            component: ConnectionSentRequestsVue,
            meta: { title: "PeerEdge  | Connection requests sent" },
          },
          {
            path: "received",
            component: ConnectionReceivedRequestsVue,
            meta: { title: "PeerEdge  | Connection requests received" },
          },
          {
            path: "",
            redirect: "/connections/requests/received",
          },
        ],
      },
      {
        path: "",
        redirect: "/connections/network",
      },
    ],
  },
  {
    path: "/profile/:id",
    component: ProflePage,
    beforeEnter: AuthGuardForOtherMember,
    meta: { title: "PeerEdge  | View member profile" },
  },
  {
    path: "/profile/edit",
    component: ProfileEditPage,
    beforeEnter: AuthGuard,
    meta: { title: "PeerEdge  | Edit profile" },
  },
  {
    path: "/profile",
    component: ProflePage,
    beforeEnter: AuthGuard,
    meta: { title: "PeerEdge  | View your profile" },
  },
  {
    path: "/logout",
    component: {},
    beforeEnter: LogoutRedirect,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  gaPageView(to.fullPath, to.meta?.title as any);
  next();
});

export default router;
