import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f75bd93"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["altIndex"]
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "avatar-container" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "profile-info" }
const _hoisted_6 = { class: "title" }
const _hoisted_7 = {
  key: 0,
  class: "subtitle"
}
const _hoisted_8 = { class: "interests-container" }
const _hoisted_9 = {
  key: 0,
  class: "interest"
}
const _hoisted_10 = {
  key: 1,
  class: "interest"
}
const _hoisted_11 = {
  key: 2,
  class: "interest"
}
const _hoisted_12 = { class: "body" }
const _hoisted_13 = { class: "section" }
const _hoisted_14 = { class: "description" }
const _hoisted_15 = { class: "section" }
const _hoisted_16 = { class: "description" }
const _hoisted_17 = {
  key: 0,
  class: "timezone"
}
const _hoisted_18 = {
  key: 1,
  class: "timezone"
}
const _hoisted_19 = {
  key: 2,
  class: "timezone"
}
const _hoisted_20 = {
  key: 3,
  class: "timezone"
}
const _hoisted_21 = {
  key: 0,
  class: "section"
}
const _hoisted_22 = { class: "description" }
const _hoisted_23 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: "root",
    altIndex: _ctx.altIndex
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: 'https://api.peeredge.africa' + _ctx.member.profile_pic
        }, null, 8, _hoisted_4),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.member.name), 1),
          (_ctx.$filters.CollaborationText(_ctx.member.research_preference))
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$filters.CollaborationText(_ctx.member.research_preference)), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        (_ctx.member.research_preference.interests?.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, " #" + _toDisplayString(_ctx.member.research_preference.interests[0]), 1))
          : _createCommentVNode("", true),
        (_ctx.member.research_preference.interests?.length > 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, " #" + _toDisplayString(_ctx.member.research_preference.interests[1]), 1))
          : _createCommentVNode("", true),
        (_ctx.member.research_preference.interests?.length > 2)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, " +" + _toDisplayString(_ctx.member.research_preference.interests?.length - 2) + " more ", 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_inline_svg, {
          class: "icon",
          src: "/assets/icons/calendar.svg"
        }),
        _createElementVNode("div", _hoisted_14, "Available from " + _toDisplayString(_ctx.availableText()), 1)
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createVNode(_component_inline_svg, {
          class: "icon",
          src: "/assets/icons/availability.svg"
        }),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", null, [
            (
                !_ctx.member.research_preference.timezones ||
                _ctx.member.research_preference.timezones.length === 0
              )
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, " Timezone: N/A "))
              : _createCommentVNode("", true),
            (_ctx.member.research_preference.timezones?.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString(_ctx.$filters.TimeZoneText(_ctx.member.research_preference.timezones[0])), 1))
              : _createCommentVNode("", true),
            (_ctx.member.research_preference.timezones?.length > 1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(_ctx.$filters.TimeZoneText(_ctx.member.research_preference.timezones[1])), 1))
              : _createCommentVNode("", true),
            (_ctx.member.research_preference.timezones?.length > 2)
              ? (_openBlock(), _createElementBlock("div", _hoisted_20, " +" + _toDisplayString(_ctx.member.research_preference.timezones?.length - 2) + " more ", 1))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      (_ctx.member.research_preference.group_size)
        ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
            _createVNode(_component_inline_svg, {
              class: "icon",
              src: "/assets/icons/about.svg"
            }),
            _createElementVNode("div", _hoisted_22, " Looking for " + _toDisplayString(_ctx.member.research_preference.group_size) + " collaborator" + _toDisplayString(_ctx.member.research_preference.group_size > 1 ? "s" : ""), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_23, [
      (_ctx.member.connect_status === _ctx.CONNECT_STATUS_TYPE.i_requested)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "action-btn",
            cancel: "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onActionClick(_ctx.CONNECT_ACTIONS.cancel)))
          }, " Cancel request "))
        : _createCommentVNode("", true),
      (_ctx.member.connect_status === _ctx.CONNECT_STATUS_TYPE.initial)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "action-btn",
            connect: "",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onActionClick(_ctx.CONNECT_ACTIONS.connect)))
          }, " Connect "))
        : _createCommentVNode("", true),
      (_ctx.member.connect_status === _ctx.CONNECT_STATUS_TYPE.other_requested)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: "action-btn",
            accept: "",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onActionClick(_ctx.CONNECT_ACTIONS.accept)))
          }, " Accept "))
        : _createCommentVNode("", true),
      (_ctx.member.connect_status === _ctx.CONNECT_STATUS_TYPE.other_requested)
        ? (_openBlock(), _createElementBlock("div", {
            key: 3,
            class: "action-btn",
            decline: "",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onActionClick(_ctx.CONNECT_ACTIONS.decline)))
          }, " Decline "))
        : _createCommentVNode("", true),
      (_ctx.member.connect_status === _ctx.CONNECT_STATUS_TYPE.connected)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 4,
            class: "action-btn",
            to: '/messages/' + _ctx.member.rocket_chat_username,
            message: "",
            onClickCapture: _ctx.onMessageMember
          }, {
            default: _withCtx(() => [
              _createTextVNode("Message")
            ]),
            _: 1
          }, 8, ["to", "onClickCapture"]))
        : _createCommentVNode("", true),
      _createVNode(_component_router_link, {
        class: "action-btn",
        to: '/profile/' + _ctx.member.public_id,
        view: "",
        onClickCapture: _ctx.onViewMemberProfile
      }, {
        default: _withCtx(() => [
          _createTextVNode("View profile")
        ]),
        _: 1
      }, 8, ["to", "onClickCapture"])
    ])
  ], 8, _hoisted_1))
}