export enum RESEARCH_TYPES {
  theory = "theory",
  applied = "applied",
}

export enum RELATIONSHIP_POSITIONS {
  mentor = "mentor",
  mentee = "mentee",
}

export enum SkillLevel {
  beginner = "beginner",
  intermediate = "intermediate",
  advanced = "advanced",
  expert = "expert",
}

export interface Skill {
  name: string;
  level?: SkillLevel;
}

export interface Preference {
  interests: string[];
  research_type?: RESEARCH_TYPES;
  area_of_work?: string;
  relationship_position?: RELATIONSHIP_POSITIONS;
  peer_to_peer?: boolean;
  project_running?: boolean;
  project_description?: boolean;
  project_progress?: number | null;
  author_main?: boolean;
  group_size?: number;
  timezones?: string[];
  availability_start?: string;
  availability_end?: string;
  skills?: Skill[];
  sought_skills: Skill[];
  languages?: string[];
}
