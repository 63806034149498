import { modalController } from "@ionic/vue";
import * as Vzue from "vue";

import { Member } from "../models";

import CompleteProfile from "../components/CompleteProfile/index.vue";

export async function PromptProfileCompletion(
  member: Member,
  options?: {
    title?: string;
    subtitle?: string;
    dismissable?: boolean;
    createAccount?: boolean;
    dismissBtnLabel?: string;
    saveBtnLabel?: string;
  }
): Promise<boolean> {
  return new Promise((res, rej) => {
    let modal: HTMLIonModalElement;

    const componentProps = {
      user: member,
      title: "Update your profile",
      saveBtnLabel: "Update profile",
      dismissBtnLabel: "Remind me later",
      dismissable: true,
      createAccount: false,
      isModal: true,
      onProfileSave: () => {
        res(true);
        modal.dismiss();
      },
      onDismiss: () => {
        res(false);
        modal.dismiss();
      },
      ...options,
    };

    modalController
      .create({
        cssClass: "modal-form-root modal-container",
        component: CompleteProfile,
        componentProps,
      })
      .then((m) => {
        modal = m;
        modal.present();
        modal.onDidDismiss().then(() => res(false));
      });
  });
}
