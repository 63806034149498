import { Vue, Options } from "vue-class-component";
import { PropType } from "vue";
import { Member, GA_CATEGORIES, GA_ACTIONS } from "../../models";
import { Events, EventTypes, gaEvent } from "../../services/utils";

@Options({
  props: {
    member: Object as PropType<Member>,
  },
})
export default class ProfileCard extends Vue {
  member?: Member;

  onProfileClick(event: Event) {
    if (this.member) {
      if (!this.member.profile_complete)
        gaEvent(GA_ACTIONS.edit_profile, GA_CATEGORIES.profiles);
      else gaEvent(GA_ACTIONS.view_own_profile, GA_CATEGORIES.profiles);
    }
  }
}
