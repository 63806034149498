import { Vue, Options } from "vue-class-component";

import {
  Member,
  CONNECT_STATUS_TYPE,
  CONNECT_ACTIONS,
  RELATIONSHIP_POSITIONS,
  Skill,
  SkillLevel,
  GA_ACTIONS,
  GA_CATEGORIES,
} from "../../models";
import { ProfileApi } from "../../services/http";
import { SkillColor, SkillOrder, SkillLevelOptions } from "../../services/data";
import { Events, EventTypes, gaEvent } from "../../services/utils";
import { TimezoneOptions } from "../../services/timezones";
import { filters } from "../../filters";
import LeftSideMenu from "../../components/LeftSideMenu";
import FormField from "../../components/FormField";
import Notifications from "../../components/Notifications";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";

@Options({
  components: {
    FormField,
    LeftSideMenu,
    VueSelect,
  },
})
export default class ProfileEdit extends Vue {
  private member!: Member;
  private selectedTimezone: string | null = null;

  async created() {
    this.member = this.$route.meta.member as Member;
  }

  mounted() {
    if (!this.member.prompted_for_profile_completion)
      this.updatePromptedForCompletion();
  }

  async updatePromptedForCompletion() {
    const newUpdated = await ProfileApi.updateProfile({
      prompted_for_profile_completion: true,
    } as Member);
    Object.assign(this.member, newUpdated);
    Events.emit(EventTypes.profileUpdate, this.member);
  }

  SkillLevelOptions = [
    { name: "Select skill level", value: "" },
    ...SkillLevelOptions,
  ];
  SkillColor = SkillColor;

  TimezoneOptions = [
    { name: "Select timezone", value: "" },
    ...TimezoneOptions,
  ];

  relationshipPositionOptions = [
    {
      name: "Peer to peer",
      value: "",
    },
    {
      name: filters.RelationshipPositionText(RELATIONSHIP_POSITIONS.mentor),
      value: RELATIONSHIP_POSITIONS.mentor,
    },
    {
      name: filters.RelationshipPositionText(RELATIONSHIP_POSITIONS.mentee),
      value: RELATIONSHIP_POSITIONS.mentee,
    },
  ];

  authorshipOptions = [
    { name: filters.AuthorshipText(), value: "" },
    { name: filters.AuthorshipText(true), value: true },
    { name: filters.AuthorshipText(false), value: false },
  ];

  onPeerToPeerChange($event: Event) {
    const inputEl = $event.target as HTMLInputElement;
    const value = inputEl.checked;
    this.member.research_preference.peer_to_peer = value;
    this.$forceUpdate();
  }

  async onFormSubmit($event: Event) {
    $event.stopPropagation();
    $event.preventDefault();
    $event.stopImmediatePropagation();
    this.member.research_preference.skills =
      this.member.research_preference.skills || [];
    this.member.research_preference.sought_skills =
      this.member.research_preference.sought_skills || [];

    this.member.prompted_for_profile_completion = true;
    console.log(this.member);

    const newUpdated = await ProfileApi.updateProfile(this.member);

    gaEvent(GA_ACTIONS.save_profile, GA_CATEGORIES.profiles);

    Object.assign(this.member, newUpdated);

    Events.emit(EventTypes.profileUpdate, this.member);

    this.goBack();
  }

  getRedirectUrl(): string | undefined {
    const query = this.$route.query;
    if (query && query.redirect) return query.redirect as string;
  }

  goBack($event?: Event) {
    $event?.stopPropagation();
    $event?.preventDefault();
    $event?.stopImmediatePropagation();

    const redirect = this.getRedirectUrl();

    if (redirect) this.$router.push(redirect);
    else this.$router.back();
  }

  onPreferenceUpdate(field: string, value: any) {
    (this.member.research_preference as any)[field] = value;

    if (field === "relationship_position" && !value) {
      this.member.research_preference.peer_to_peer = true;
    }

    this.$forceUpdate();
  }

  onMemberUpdate(field: string, value: any, inputEl?: HTMLInputElement) {
    if (field === "school") {
      if (/(NA)|(N\/A)/i.test(value)) {
        inputEl?.setCustomValidity(`Enter the full name`);
        return;
      } else inputEl?.setCustomValidity("");
    }

    (this.member as any)[field] = value;
    this.$forceUpdate();
  }

  onAddNewInterest(interest: string) {
    const interests = this.member.research_preference.interests || [];
    if (!interests.includes(interest)) interests.push(interest);
    this.member.research_preference.interests = interests;

    this.$forceUpdate();
  }

  onRemoveInterest(interest: string) {
    const interests = this.member.research_preference.interests || [];
    const index = interests.indexOf(interest);
    if (index >= 0) interests.splice(index, 1);
    this.member.research_preference.interests = interests;

    this.$forceUpdate();
  }

  onAddNewTimeZone(selectedOption: any) {
    if (selectedOption && selectedOption.value) {
      const timezone = selectedOption.value;
      const timezones = this.member.research_preference.timezones || [];

      if (!timezones.includes(timezone)) {
        timezones.push(timezone);
        this.member.research_preference.timezones = timezones;
        this.selectedTimezone = null; // Clear the selected timezone
        this.$forceUpdate();
      }
    }
  }

  onRemoveTimezone(timezone: string) {
    const timezones = this.member.research_preference.timezones || [];
    const index = timezones.indexOf(timezone);
    if (index >= 0) timezones.splice(index, 1);
    this.member.research_preference.timezones = timezones;

    this.$forceUpdate();
  }

  skill_level = "";
  sought_skill_level = "";

  onAddNewSkill(field: "skills" | "sought_skills", name: string) {
    const level = (
      field === "skills" ? this.skill_level : this.sought_skill_level
    ) as any;
    const skills = this.member.research_preference[field] || [];
    const skill = skills.find((s) => s.name === name);
    if (skill) skill.level = level;
    else skills.push({ name, level });

    this.member.research_preference[field] = skills;
    this.$forceUpdate();
  }

  onRemoveSkill(field: "skills" | "sought_skills", skill: Skill) {
    const skills = this.member.research_preference[field] || [];
    const index = skills.findIndex((s) => s.name === skill.name);
    if (index >= 0) skills.splice(index, 1);

    this.member.research_preference[field] = skills;
    this.$forceUpdate();
  }
}
