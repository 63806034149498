import { Member } from "../models";
import Notifications from "../components/Notifications";
import { Events, EventTypes } from "./utils";
import router from "../index/routes";

const win = window as any;
const ROCKET_CHAT_URL = process.env.VUE_APP_ROCKET_CHAT_URL;

export async function getRocketChatIFrame(
  member?: Member
): Promise<HTMLIFrameElement | null> {
  if (!win["rocketChatIframePromise"]) {
    if (!member) return Promise.resolve(null);

    win["rocketChatIframePromise"] = new Promise((res, rej) => {
      if (isInRocketChatIframe()) return;
      console.log("Insinde win promise");
      const iframe = document.getElementById(
        "rocket-chat-iframe"
      )! as HTMLIFrameElement;
      iframe.style.setProperty("width", "100%");
      iframe.style.setProperty("border", "none");
      iframe.src = ROCKET_CHAT_URL;
      console.log("iframe", iframe);
      res(iframe);
      const onEvent = (event: MessageEvent) => {
        if (ROCKET_CHAT_URL.startsWith(event.origin)) {
          if (event.data?.eventName === "startup") {
            RocketChatSetIsEmbeddedView();

            iframe.contentWindow!.postMessage(
              {
                externalCommand: "login-with-token",
                token: member.rocket_chat_token,
              },
              "*"
            );
          } else if (event.data?.eventName === "Custom_Script_Logged_In") {
            console.log("iframe resolving test");

            res(iframe);
          } else if (event.data?.eventName === "unread-changed") {
            Events.emit(EventTypes.unReadMessage, event.data.data || 0);
          } else if (event.data?.eventName === "ca2-open-member-search") {
            router.push("/home");
          }
        }
      };
      window.addEventListener("message", onEvent);
    });
  }
  console.log("rocketchat testing");
  console.log(win["rocketChatIframePromise"]);

  return win["rocketChatIframePromise"];
}

export async function logoutRocketChat(member: Member) {
  const loader: any = await Notifications.loader({});

  return new Promise((res, rej) => {
    const iframePromise = win["rocketChatIframePromise"];

    if (!iframePromise) {
      res(true);
      return;
    }

    setTimeout(() => res(true), 3500);

    iframePromise
      .then((iframe: any) => {
        try {
          iframe.contentWindow!.postMessage(
            {
              externalCommand: "logout",
            },
            "*"
          );

          const onEvent = (event: MessageEvent) => {
            console.log("onEvent creation");
            if (
              ROCKET_CHAT_URL.startsWith(event.origin) &&
              event.data?.eventName === "Custom_Script_On_Logout"
            ) {
              res(true);
              window.removeEventListener("message", onEvent);
            }
          };
          window.addEventListener("message", onEvent);
        } catch (exception) {
          res(true);
          console.log(exception);
        }
      })
      .catch((err: any) => res(true));
  }).finally(() => {
    loader.dismiss();
  });
}

export function RocketChatgoToUrl(iframe: HTMLIFrameElement, path: string) {
  try {
    iframe.contentWindow!.postMessage(
      {
        externalCommand: "go",
        path,
      },
      "*"
    );
  } catch (error) {
    console.log(error);
  }
}

export async function RocketChatCA2LoginToken(token: string | null) {
  if (!isInRocketChatIframe()) {
    const iframe = await win["rocketChatIframePromise"];

    if (iframe) {
      iframe.contentWindow!.postMessage(
        {
          event: "set-ca2-login-token",
          token,
        },
        "*"
      );
    }
  } else if (isInRocketChatIframe() && window.parent) {
    window.parent.postMessage(
      {
        event: "set-ca2-login-token",
        token,
      },
      "*"
    );
  }
}

export async function RocketChatSetIsEmbeddedView() {
  const iframe = await win["rocketChatIframePromise"];

  if (iframe) {
    iframe.contentWindow!.postMessage(
      {
        event: "set-ca2-embedded-view",
      },
      "*"
    );
  }
}

export function RocketChatIframeLoginToken(loginToken: string | null) {
  if (isInRocketChatIframe() && window.parent) {
    window.parent.postMessage(
      {
        event: "login-with-token",
        loginToken,
      },
      "*"
    );
  }
}

let _isInRocketChatIframe = false;

export function isInRocketChatIframe(): boolean {
  return _isInRocketChatIframe;
}

export function determineWhetherInRocketChatIframe() {
  console.log("determining rocket chat iframe");
  const search = new URLSearchParams(location.search);
  _isInRocketChatIframe = search.has("workspace_embedded");

  console.log("determining rocket chat iframe", isInRocketChatIframe());
}

determineWhetherInRocketChatIframe();
