import { Vue, Options } from "vue-class-component";
import { PropType } from "vue";
import { Conversation, Member } from "../../models";
import { isMenuOpen } from "../../services/utils";
import { AuthApi } from "../../services/http";
import {
  getRocketChatIFrame,
  RocketChatgoToUrl,
} from "../../services/rocket-chat";

const ROCKET_CHAT_URL = process.env.VUE_APP_ROCKET_CHAT_URL;

export interface ConversationOptions {
  member?: Member;
  conversation?: Conversation;
}

@Options({
  props: {
    conversationOption: Object as PropType<ConversationOptions>,
  },
})
export default class ConversationWindow extends Vue {
  conversationOption: ConversationOptions | null = null;
  windowUrl: string | null = null;
  iframe: any;

  async created() {
    this.iframe = await getRocketChatIFrame(AuthApi.member!);
    this.onWindowChange(this.conversationOption);

    this.$watch(
      "conversationOption",
      (options: ConversationOptions) => {
        this.onWindowChange(options);
      },
      { deep: true }
    );

    this.$watch("$route", (to: any) => {
      if (to.path.startsWith("/messages")) {
        this.onResize();
      }
    });
  }

  async mounted() {
    this.iframe = await getRocketChatIFrame(AuthApi.member!);
    window.addEventListener("resize", this.resizeThrottle);
    this.onResize();
  }

  resizeTimerId: any;

  resizeThrottle() {
    clearTimeout(this.resizeTimerId);
    this.resizeTimerId = setTimeout(() => this.onResize(), 100);
  }

  async onResize() {
    const iframeContainer = (await this.resolveContainer())!;
    const top = iframeContainer.getBoundingClientRect().top;
    const left = iframeContainer.getBoundingClientRect().left;
    const width =
      iframeContainer.getBoundingClientRect().width || this.defaultWidth();

    const height = window.innerHeight - top;
    this.iframe.style.setProperty("top", `${top}px`);
    this.iframe.style.setProperty("left", `${left}px`);
    this.iframe.style.setProperty("width", `${width}px`);
    this.iframe.style.setProperty("height", `${height}px`);
  }

  defaultWidth() {
    if (isMenuOpen()) {
      return window.innerWidth - 300;
    } else return window.innerWidth;
  }

  beforeUnmount() {
    window.removeEventListener("resize", this.resizeThrottle);
  }

  async onWindowChange(conversationOption: ConversationOptions | null) {
    if (conversationOption) {
      this.windowUrl = `/direct/${
        conversationOption.member?.rocket_chat_username ||
        conversationOption?.conversation?.id
      }`;
    } else {
      this.windowUrl = "/";
    }

    RocketChatgoToUrl(this.iframe, this.windowUrl);
    //this.iframe.src = this.windowUrl;
  }

  resolveContainer(): Promise<HTMLElement | undefined> {
    let count = 0;

    return new Promise((res, rej) => {
      const f = () => {
        const iframeContainer = this.$refs["iframeContainer"] as HTMLElement;
        const width = iframeContainer.getBoundingClientRect().width;
        if (width != 0) res(iframeContainer);
        else {
          count++;

          if (count < 10) setTimeout(f, 200);
          else res(iframeContainer);
        }
      };
      f();
    });
  }
}
