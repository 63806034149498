import { Vue, Options } from "vue-class-component";
import { PropType } from "vue";
import moment from "moment";
import axios from "axios";
import { Feeds } from "../../models";
import {
  FeedsApi,
  FeedsLikesAPI,
  FeedsCommentApi,
  FeedsdisLikesAPI,
  FeedsShareApi,
} from "../../services/http";

@Options({
  props: {
    feed: Object as PropType<Feeds>,
    altIndex: String,
  },
})
export default class FeedsListItem extends Vue {
  feed!: Feeds;
  altIndex = "a";
  timeSincePost = "";
  liked = false;
  likeCount: any = 0;
  newComment = "";
  comments: any[] = [];
  disliked = false;
  dislikeCount: any = 0;
  share: any[] = [];

  created() {
    this.timeSincePost = moment(this.feed.created).fromNow();
    this.getLikes();
    this.getComments();
    this.getdisLikes();
  }

  async shareLink() {
    const feedid = this.feed.id;
    console.log(`https://peeredge.africa/feeds/${feedid}`);
    return `https://peeredge.africa/feeds/${feedid}`;
  }

  async like() {
    try {
      if (this.liked) {
        this.liked = false;
        this.likeCount--;
      } else {
        if (this.feed.id === undefined) return;
        FeedsLikesAPI.like(this.feed.id, true);

        this.liked = true;
        this.likeCount++;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getLikes() {
    try {
      if (this.feed.id === undefined) return;
      const response = await FeedsLikesAPI.getLikes(this.feed.id);
      this.likeCount = response.total_likes;
    } catch (error) {
      console.error(error);
    }
  }

  async dislike() {
    try {
      if (this.disliked) {
        this.disliked = false;
        this.dislikeCount--;
      } else {
        if (this.feed.id === undefined) return;
        FeedsdisLikesAPI.dislike(this.feed.id, true);

        this.disliked = true;
        this.dislikeCount++;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getdisLikes() {
    try {
      if (this.feed.id === undefined) return;
      const response = await FeedsdisLikesAPI.getdisLikes(this.feed.id);
      this.dislikeCount = response.total_dislikes;
    } catch (error) {
      console.error(error);
    }
  }

  goToFeedPage() {
    this.$router.push({
      name: "FeedDetails",
      params: { feedId: this.feed.id },
    });
  }

  async getComments() {
    try {
      if (this.feed.id === undefined) return;
      this.comments = await FeedsCommentApi.list(this.feed.id);
      //const response = await axios.get(
      //  `http://localhost:8000/api/feeds/${this.feed.id}/comments`
      //);
    } catch (error) {
      console.error(error);
    }
  }

  async postComment() {
    try {
      const response = await axios.post(
        `http://localhost:8000/api/feeds/${this.feed.id}/comments`,
        {
          text: this.newComment,
        }
      );

      const resposne = FeedsCommentApi.add(this.feed.id, {
        text: this.newComment,
      });

      this.comments.push(response.data);
      this.newComment = "";

      this.comments.push(response.data);
      this.newComment = "";
    } catch (error) {
      console.error(error);
    }
  }
}
