import { Vue, Options } from "vue-class-component";
import { PropType } from "vue";

import moment from "moment";

import {
  ResearchJob,
  JobTypeNames,
  GA_ACTIONS,
  GA_CATEGORIES,
} from "../../models";
import Notifications from "../../components/Notifications";
import { gaEvent } from "../../services/utils";

@Options({
  props: {
    job: Object as PropType<ResearchJob>,
    altIndex: String,
  },
})
export default class ResearchJobListItem extends Vue {
  job!: ResearchJob;
  JobTypeNames = JobTypeNames;

  altIndex = "a";
  showId = false;

  onJobClick(event: Event) {
    gaEvent(GA_ACTIONS.view_job, GA_CATEGORIES.jobs, this.job?.id);
  }

  created() {
    this.showId =
      new URLSearchParams(location.search).get("show_id") === "true";
  }
}
