import { Vue, Options } from "vue-class-component";
import { StatsInterface } from "../../models";
import { Events, EventTypes } from "../../services/utils";
import { Stats } from "../../services/data";

export default class Connections extends Vue {
  static _menuItems = [
    { id: "received", count: 0, name: "Received", link: "received" },
    { id: "sent", count: 0, name: "Sent", link: "sent" },
  ];

  menuItems = Connections._menuItems;

  mounted() {
    this.onStatsUpdated({ detail: Stats } as CustomEvent);
    Events.on(EventTypes.statsUpdated, this.onStatsUpdated);
  }

  unmounted() {
    Events.off(EventTypes.statsUpdated, this.onStatsUpdated);
  }

  onStatsUpdated(event: CustomEvent) {
    const stats = event.detail as StatsInterface;

    const receivedItem = Connections._menuItems.find(
      (m) => m.id === "received"
    )!;
    receivedItem.count = stats.connectionStats.other_requested;

    const sentItem = Connections._menuItems.find((m) => m.id === "sent")!;
    sentItem.count = stats.connectionStats.i_requested;

    this.$forceUpdate();
  }
}
