import { Vue, Options } from "vue-class-component";
import { AuthApi } from "../../services/http";
import Notifications from "../../components/Notifications";
import FormField from "../../components/FormField";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

@Options({
  components: {
    FormField,
    Header,
    Footer,
  },
})
export default class ForgotPassword extends Vue {
  redirectQSearch = "";

  formData = {
    email: "",
  };

  getRedirectUrl(): string | undefined {
    const query = this.$route.query;
    if (query && query.redirect) return query.redirect as string;
  }

  created() {
    const redirectUrl = this.getRedirectUrl();
    if (redirectUrl) this.redirectQSearch = `?redirect=${redirectUrl}`;
  }

  async onFormSubmit($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
    $event.stopImmediatePropagation();

    const member = await AuthApi.forgotPassword(this.formData);
    const redirectUrl = this.getRedirectUrl();

    const query = {} as any;
    if (redirectUrl) query.redirect = redirectUrl;

    Notifications.alert(
      "Password reset instructions have been sent to your email",
      [{ text: "Okay", value: "" }]
    );

    this.$router.push({ path: "/login", query, replace: true });
  }

  onInputUpdate(name: "email", inputEvent: InputEvent) {
    const inputEl = inputEvent.target as HTMLInputElement;
    this.formData[name] = inputEl.value;
  }
}
