import { Preference } from "./preference";
import { ConnectionStats } from "./stats";

export enum CONNECT_ACTIONS {
  connect = "connect",
  accept = "accept",
  decline = "decline",
  profile = "profile",
  message = "message",
  cancel = "cancel",
  remove = "remove",
}

export enum CONNECT_STATUS_TYPE {
  initial = "initial",
  other_requested = "other_requested",
  i_requested = "i_requested",
  connected = "connected",
}

export interface Member {
  id: number;
  name: string;
  email?: string;
  school_level: string;
  school: string;
  profile_pic: string;
  research_preference: Preference;
  connect_status?: CONNECT_STATUS_TYPE;
  profile_complete: boolean;
  about?: string;
  email_confirmed?: boolean;
  connection_stats?: ConnectionStats;
  rocket_chat_username?: string;
  rocket_chat_token?: string;
  hide_profile?: boolean;
  public_id: string;
  prompted_for_profile_completion?: boolean;
}
