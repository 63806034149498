import { Vue, Options } from "vue-class-component";
import { PropType } from "vue";

import moment from "moment";

import { ResearchLibrary, GA_ACTIONS, GA_CATEGORIES } from "../../models";
import Notifications from "../../components/Notifications";
import { gaEvent } from "../../services/utils";

@Options({
  props: {
    library: Object as PropType<ResearchLibrary>,
    altIndex: String,
  },
})
export default class ResearchLibraryListItem extends Vue {
  library!: ResearchLibrary;

  altIndex = "a";
  showId = false;

  onLibraryClick(event: Event) {
    gaEvent(GA_ACTIONS.view_library, GA_CATEGORIES.libraries, this.library?.id);
  }

  created() {
    this.showId =
      new URLSearchParams(location.search).get("show_id") === "true";
  }
}
