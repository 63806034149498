import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a03954a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "menu-container" }
const _hoisted_3 = { class: "main-content no-padding" }
const _hoisted_4 = { class: "side-menu-trigger" }
const _hoisted_5 = { class: "window-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LeftSideMenu = _resolveComponent("LeftSideMenu")!
  const _component_MessagesHeader = _resolveComponent("MessagesHeader")!
  const _component_ConversationWindow = _resolveComponent("ConversationWindow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_LeftSideMenu)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_MessagesHeader)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_ConversationWindow, { conversationOption: _ctx.conversationOption }, null, 8, ["conversationOption"])
      ])
    ])
  ]))
}