<!-- Faqs.vue -->
<template>
  <div>
    <iframe
      src="../../../public/faq.html"
      width="100%"
      height="100%"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
import component from "./component";
export default component;
// Component logic, if needed
</script>

<style scoped>
/* Component styles, if needed */
</style>
