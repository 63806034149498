import * as models from "../models";
import Notifications from "../components/Notifications";

declare const window: any;

const CONNECT_ACTIONS = models.CONNECT_ACTIONS;

export const MemberListActions = [
  { name: "Connect", type: CONNECT_ACTIONS.connect, class: "" },
  {
    name: "Message",
    type: CONNECT_ACTIONS.message,
    class: "btn-green-outline",
  },
  { name: "Accept", type: CONNECT_ACTIONS.accept, class: "btn-green-outline" },
  { name: "Cancel", type: CONNECT_ACTIONS.cancel, class: "btn-red-outline" },
  { name: "Decline", type: CONNECT_ACTIONS.decline, class: "btn-red-outline" },
  { name: "View profile", type: CONNECT_ACTIONS.profile, class: "" },
  {
    name: "Disconnect",
    type: CONNECT_ACTIONS.remove,
    class: "btn-red-outline",
  },
];

export const MemberConnectActions = {
  [models.CONNECT_STATUS_TYPE.initial]: {
    [CONNECT_ACTIONS.connect]: true,
    [CONNECT_ACTIONS.profile]: true,
  },
  [models.CONNECT_STATUS_TYPE.other_requested]: {
    [CONNECT_ACTIONS.accept]: true,
    [CONNECT_ACTIONS.decline]: true,
    [CONNECT_ACTIONS.profile]: true,
  },
  [models.CONNECT_STATUS_TYPE.i_requested]: {
    [CONNECT_ACTIONS.cancel]: true,
    [CONNECT_ACTIONS.profile]: true,
  },
  [models.CONNECT_STATUS_TYPE.connected]: {
    [CONNECT_ACTIONS.message]: true,
    [CONNECT_ACTIONS.profile]: true,
    [CONNECT_ACTIONS.remove]: true,
  },
};

export const SkillOrder = {
  [models.SkillLevel.beginner]: 3,
  [models.SkillLevel.intermediate]: 2,
  [models.SkillLevel.advanced]: 1,
  [models.SkillLevel.expert]: 0,
};

export const SkillColor = {
  [models.SkillLevel.beginner]: "#626c72",
  [models.SkillLevel.intermediate]: "#17a2b8",
  [models.SkillLevel.advanced]: "#007bff",
  [models.SkillLevel.expert]: "#28a745",
};

export const SkillLevelOptions = [
  { name: "Beginner", value: models.SkillLevel.beginner },
  { name: "Intermediate", value: models.SkillLevel.intermediate },
  { name: "Advanced", value: models.SkillLevel.advanced },
  { name: "Expert", value: models.SkillLevel.expert },
];

export class Stats {
  static connectionStats: models.ConnectionStats = {
    other_requested: 0,
    connected: 0,
    i_requested: 0,
  };
  static unReadMessageCount = 0;
}
