import { Vue, Options } from "vue-class-component";
import { PropType } from "vue";

import moment from "moment";

import { ResearchCommunity, GA_ACTIONS, GA_CATEGORIES } from "../../models";
import Notifications from "../../components/Notifications";
import { gaEvent } from "../../services/utils";

@Options({
  props: {
    community: Object as PropType<ResearchCommunity>,
    altIndex: String,
  },
})
export default class ResearchCommunityListItem extends Vue {
  community!: ResearchCommunity;

  altIndex = "a";
  showId = false;

  onCommunityClick(event: Event) {
    gaEvent(
      GA_ACTIONS.view_community,
      GA_CATEGORIES.communities,
      this.community?.id
    );
  }

  created() {
    this.showId =
      new URLSearchParams(location.search).get("show_id") === "true";
  }
}
