import LeftSideMenu from "../components/LeftSideMenu";
import { Vue, Options } from "vue-class-component";
import { getRocketChatIFrame } from "../services/rocket-chat";
import {
  closeMenu,
  hideMessagesWindow,
  setMessageWindowOnLocationChange,
  Events,
  EventTypes,
  waitFor,
} from "../services/utils";

import { RocketChatIframeLoginToken } from "../services/rocket-chat";

import { PromptProfileCompletion } from "../services/prompt-profile-completion";
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { Stats } from "../services/data";
import { Member } from "../models";
import { AuthApi, ProfileApi } from "../services/http";
import Notifications from "@/components/Notifications";

@Options({
  components: {
    LeftSideMenu,
    IonApp,
  },
})
export default class App extends Vue {
  AuthApi = AuthApi;

  mounted() {
    this.setUpLeftSlider();
    this.$watch("$route", async (to: any) => {
      const iframe = await getRocketChatIFrame(AuthApi.member as any);
      if (iframe) {
        if (!AuthApi.member) hideMessagesWindow(iframe);
        else setMessageWindowOnLocationChange(iframe);
      }
    });

    Events.on(EventTypes.connectionStatusUpdated, () =>
      ProfileApi.getProfile()
    );

    Events.on(EventTypes.unReadMessage, (event: CustomEvent) => {
      Stats.unReadMessageCount = event.detail as number;
      Events.emit(EventTypes.statsUpdated, Stats);
    });

    Events.on(EventTypes.profileApiLoaded, (event: CustomEvent) => {
      const member = event.detail as Member;
      RocketChatIframeLoginToken(member.rocket_chat_token!);
      Stats.connectionStats = member.connection_stats!;
      Events.emit(EventTypes.statsUpdated, Stats);
    });

    Events.on(EventTypes.profileApiLoaded, this.onUserLoaded);
  }

  async onUserLoaded(event: CustomEvent) {
    Events.off(EventTypes.profileApiLoaded, this.onUserLoaded);

    const member = event.detail as Member;

    await waitFor(0.3);

    if (!member.profile_complete && this.$route.path !== "/profile/edit") {
      const completed = await PromptProfileCompletion(member, { subtitle: "" });
      if (completed) {
        Notifications.toast("Your profile is now updated");
      }
    }
  }

  setUpLeftSlider() {
    const leftMenuContainer = this.$refs.leftSlider as HTMLElement;
    leftMenuContainer.addEventListener("click", (event) => {
      if (event.target === leftMenuContainer) closeMenu();
    });
  }
}
