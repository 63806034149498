export enum JobType {
  full_time = "full_time",
  part_time = "part_time",
  contract = "contract",
  internship = "internship",
}

export const JobTypeNames = {
  [JobType.full_time]: "Full time",
  [JobType.part_time]: "Part time",
  [JobType.contract]: "Contract",
  [JobType.internship]: "Internship",
};

export interface ResearchJob {
  id: number;
  name: string;
  company_name: string;
  tag_line: string;
  logo?: string;
  position: string;
  job_type: JobType;
  tags: string[];
  is_remote: boolean;
  location_name?: any;
  application_deadline?: string;
  external_link: string;
  enabled: boolean;
}
