import { Vue, Options } from "vue-class-component";

import FormField from "../FormField";

import { RELATIONSHIP_POSITIONS } from "../../models";
import * as $filters from "../../filters";

interface SearchParams {
  q?: string;
  availability_start?: string;
  availability_end?: string;
  project_running?: boolean;
  peer_to_peer?: boolean;
  relationship_position?: RELATIONSHIP_POSITIONS;
  exclude_connections?: boolean;
}

@Options({
  components: { FormField },
  props: {
    inputDebouce: {
      type: Number,
      default: 500,
    },
  },
})
export default class MemberSearchFilter extends Vue {
  collaborationOptions = [
    { name: "All collaborations", value: "" },
    { name: "Peer to peer", value: "peer_to_peer" },
    { name: "Advisor", value: RELATIONSHIP_POSITIONS.mentor },
    { name: "Advisee", value: RELATIONSHIP_POSITIONS.mentee },
  ];

  searchParams: SearchParams = {};

  timerId?: number;

  inputDebouce?: number;

  throttleInput(name: "q", $event: Event) {
    clearTimeout(this.timerId);
    this.timerId = setTimeout(
      () => this.onInputChange(name, $event),
      this.inputDebouce
    );
  }

  onAvailableRangeChange($event: any) {
    this.searchParams.availability_start = $event.start;
    this.searchParams.availability_end = $event.end;
    this.$emit("paramsChange", { ...this.searchParams });
  }

  onInputChange(
    name:
      | "q"
      | "relationship_position"
      | "exclude_connections"
      | "project_running",
    $event: Event
  ) {
    const inputEl = $event.target as HTMLInputElement;
    const value =
      inputEl.type === "checkbox" ? inputEl.checked : (inputEl.value as any);

    if (name === "relationship_position") {
      if (!value) {
        this.searchParams.relationship_position = undefined;
        this.searchParams.peer_to_peer = undefined;
      } else if (value === "peer_to_peer") {
        this.searchParams.peer_to_peer = true;
      } else this.searchParams.relationship_position = value;
    } else {
      if (value) this.searchParams[name] = value;
      else this.searchParams[name] = undefined;
    }

    this.$emit("paramsChange", { ...this.searchParams });
  }
}
