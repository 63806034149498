import { Vue, Options } from "vue-class-component";
import LeftSideMenu from "../../components/LeftSideMenu";
import { StatsInterface } from "../../models";
import { Events, EventTypes } from "../../services/utils";
import { Stats } from "../../services/data";

@Options({
  components: { LeftSideMenu },
})
export default class Connections extends Vue {
  static _menuItems = [
    {
      id: "network",
      name: "Your network",
      link: "/connections/network",
      count: 0,
    },
    {
      id: "requests",
      name: "Connection requests",
      link: "/connections/requests",
      count: 0,
    },
  ];

  menuItems = Connections._menuItems;

  mounted() {
    this.onStatsUpdated({ detail: Stats } as CustomEvent);
    Events.on(EventTypes.statsUpdated, this.onStatsUpdated);
  }

  unmounted() {
    Events.off(EventTypes.statsUpdated, this.onStatsUpdated);
  }

  onStatsUpdated(event: CustomEvent) {
    const stats = event.detail as StatsInterface;

    const networkItem = Connections._menuItems.find((m) => m.id === "network")!;
    networkItem.count = stats.connectionStats.connected;

    const requestsItem = Connections._menuItems.find(
      (m) => m.id === "requests"
    )!;
    requestsItem.count =
      (stats.connectionStats.i_requested || 0) +
      (stats.connectionStats.other_requested || 0);

    this.$forceUpdate();
  }
}
