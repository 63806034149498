import { Vue, Options } from "vue-class-component";
import { AuthApi } from "../../services/http";
import Notifications from "../../components/Notifications";
import FormField from "../../components/FormField";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CompleteProfile from "../../components/CompleteProfile";

@Options({
  components: {
    FormField,
    Header,
    Footer,
    CompleteProfile,
  },
})
export default class CreateAccount extends Vue {
  redirectQSearch = "";

  firstStepComplete = false;

  formData = {
    email: "",
    password: "",
    confirm_password: "",
    school: "",
    school_level: "",
    first_name: "",
    last_name: "",
  };

  getRedirectUrl(): string | undefined {
    const query = this.$route.query;
    if (query && query.redirect) return query.redirect as string;
  }

  init() {
    this.firstStepComplete = false;
    this.formData = {
      email: "",
      password: "",
      confirm_password: "",
      school: "",
      school_level: "",
      first_name: "",
      last_name: "",
    };
    const redirectUrl = this.getRedirectUrl();
    if (redirectUrl) this.redirectQSearch = `?redirect=${redirectUrl}`;
  }

  async created() {
    this.init();
    this.$watch("$route", (to: any) => {
      if (to.fullPath.startsWith("/create-account")) {
        this.init();
        this.$forceUpdate();
      }
    });
  }

  async onFormSubmit($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
    $event.stopImmediatePropagation();

    // Password and confirmation validation
    if (this.formData.password !== this.formData.confirm_password) {
      // Handle password mismatch (show error, prevent form submission, etc.)
      Notifications.alert("The Passwords entered do not match.", [
        { text: "Okay", value: "" },
      ]);
      //    alert("The Passwords do not match"); // Display an alert
      //  return;
    }

    await AuthApi.registerationAttempt({
      email: this.formData.email,
      first_name: this.formData.first_name,
      last_name: this.formData.last_name,
    });

    this.firstStepComplete = true;
  }

  onDone() {
    const redirectUrl = this.getRedirectUrl();

    const query = {} as any;
    if (redirectUrl) query.redirect = redirectUrl;

    Notifications.alert(
      "Click the link sent to your email to confirm your account",
      [{ text: "Okay", value: "" }]
    );

    this.$router.push({ path: "/", query, replace: true });
  }

  onInputUpdate(
    name:
      | "email"
      | "password"
      | "school"
      | "school_level"
      | "first_name"
      | "last_name",
    inputEvent: InputEvent
  ) {
    const inputEl = inputEvent.target as HTMLInputElement;
    if (name === "school") {
      if (/(NA)|(N\/A)/i.test(inputEl.value)) {
        inputEl.setCustomValidity(`Enter the full name`);
        return;
      } else inputEl.setCustomValidity("");
    }
    this.formData[name] = inputEl.value;
  }
}
