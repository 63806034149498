import { Vue, Options } from "vue-class-component";
import { AuthApi } from "../../services/http";

import FormField from "../../components/FormField";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { RouterLink } from "vue-router";

@Options({
  components: {
    FormField,
    Header,
    Footer,
  },
})
export default class Login extends Vue {
  [x: string]: any;
  redirectQSearch = "";

  formData = {
    email: "",
    password: "",
  };

  getRedirectUrl(): string | undefined {
    const query = this.$route.query;
    if (query && query.redirect) return query.redirect as string;
  }

  created() {
    const redirectUrl = this.getRedirectUrl();
    if (redirectUrl) this.redirectQSearch = `?redirect=${redirectUrl}`;
  }

  async onFormSubmit($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
    $event.stopImmediatePropagation();

    const member = await AuthApi.login(this.formData);
    const redirectUrl = this.getRedirectUrl();

    window.location.href = redirectUrl || "/home";
  }

  onInputUpdate(name: "email" | "password", inputEvent: InputEvent) {
    const inputEl = inputEvent.target as HTMLInputElement;
    this.formData[name] = inputEl.value;
  }
}
