import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7cd7ba6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "field-container q" }
const _hoisted_2 = { class: "field-container select" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!

  return (_openBlock(), _createElementBlock("form", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_FormField, {
        type: "text",
        placeholder: "search for grants",
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.throttleInput('q', $event)))
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FormField, {
        type: "select",
        options: _ctx.jobTypeOptions,
        placeholder: "select grant type",
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onInputChange('job_type', $event)))
      }, null, 8, ["options"])
    ])
  ]))
}