import { Vue, Options } from "vue-class-component";
import { PropType } from "vue";

import moment from "moment";

import { GA_ACTIONS, GA_CATEGORIES } from "../../models";
import { ResearchGrant } from "@/models/research_grants";
import Notifications from "../../components/Notifications";
import { gaEvent } from "../../services/utils";

@Options({
  props: {
    grant: Object as PropType<ResearchGrant>,
    altIndex: String,
  },
})
export default class ResearchGrantListItem extends Vue {
  grant!: ResearchGrant;

  altIndex = "a";
  showId = false;

  onGrantClick(event: Event) {
    gaEvent(GA_ACTIONS.view_grant, GA_CATEGORIES.grants, this.grant?.id);
  }

  created() {
    this.showId =
      new URLSearchParams(location.search).get("show_id") === "true";
  }
}
